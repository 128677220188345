import { callAPIWithGETMethod, callAPIWithPOSTMethod } from "../common/api";
import CommonResponse from "../http";
import { AddMemberResponse } from "../member/model";
import { AddSocialMemberRequest } from "./model";

export const createMember = async (
  addSocialMemberRequest: AddSocialMemberRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    { url: "/v1/member", body: addSocialMemberRequest },
    isLoading
  );
};

export const addSocialMember = async (
  addSocialMemberRequest: AddSocialMemberRequest,
  isLoading = true
): Promise<CommonResponse<AddMemberResponse>> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/memberSocial",
      body: addSocialMemberRequest,
    },
    isLoading
  );
};
