import { action, makeObservable } from "mobx";
import { socialLogin, updateMember } from "../api/member/api";
import {
  SocialLoginRequest,
  SocialNotLoginResponseVO,
  Terms,
  UserInfo,
} from "../api/member/model";
// import { getNaverProfile } from "../api/naverLogin/api";
import { addSocialMember } from "../api/socialMember/api";
import { AddSocialMemberRequest } from "../api/socialMember/model";
// import { AppleCredentialState } from "../constants/AppleState.enum";
import {
  MemberStateCode,
  SocialTypeCode,
  StatusCode,
  SuccessOrNot,
} from "../constants/Common.enum";
import { LAST_LOGIN_TYPE, REDIRECT_PATH } from "../constants/Storage";
// import { ApplePerformResponse } from "../native/appleLogin/model";
// import { applePerformRequest, getAppleCredentialStateForUser } from "../native/appleLogin/native";
// import { googleConfigure, googleLogin } from "../native/googleLogin/native";
// import { naverSignIn, naverSignOut, naverUnlink } from "../native/naverLogin/natvie";
// import { kakaoGetProfile, kakaoSingIn } from "../native/kakaoLogin/native";
// import { canGoBack, goBack, navigate, push, reset } from "../navigation/NavigationFunction";
import {
  getTempMemberFromStorage,
  removeTempMemberFromStorage,
} from "../service/Preference.service";
import {
  mergeAndSetSessionUserInStorage,
  setSessionUserInStorage,
} from "../service/Session.service";
import CommonStore from "./CommonStore";
import ProfileStore from "./ProfileStore";
import { isNotNormalMember } from "../utils/login";
import MemberStore from "./MemberStore";
// import { getKakaoTerms } from "../api/kakaoLogin/api";
import { getLastTerms } from "../api/terms/api";
import { KakaoTermTypeCode, TermsTypeCode } from "../constants/Terms.enum";
import { AllowTypeCode } from "../constants/CommonCode.enum";
import RecommenderStore from "./RecommenderStore";
import MainStore from "./MainStore";
import { t } from "i18next";
import {
  getKakaoServiceTerms,
  getKakaoToken,
  getKakaoUser,
  kakaoAuthorize,
} from "../api/kakaoLogin/api";
import { getStringRemoteConfig } from "../utils/remoteConfig";
import {
  getNaverToken,
  getNaverUser,
  naverAuthorize,
  naverSocialDelete,
  naverSocialLogin,
  naverUnlink,
} from "../api/naverLogin/api";
import {
  getGoogleToken,
  getGoogleUser,
  googleAuthorize,
} from "../api/google/api";
import { SignUpCode } from "../constants/SignUp.enum";
import { goRedirect, goTo } from "../hooks/navigate/NavigateFunction";

export interface AddSocialMemberStoreRequest {
  socialTypeCode: SocialTypeCode;
  accessToken: string;
  phoneNumber?: string;
  phoneConfirmFlag?: boolean;
  nonce?: string;
  terms: Terms[];
}

interface GetUser {
  socialTypeCode: SocialTypeCode;
  accessToken: string;
  nonce?: string;
  emailAddress: string;
}

// const iosKeys = {
//   kConsumerKey: Config.REACT_NATIVE_NAVER_CONSUMER_KEY,
//   kConsumerSecret: Config.REACT_NATIVE_NAVER_CONSUMER_SECRET,
//   kServiceAppName: "220cordncode",
//   kServiceAppUrlScheme: "mogascheme",
// };

// const androidKeys = {
//   kConsumerKey: Config.REACT_NATIVE_NAVER_CONSUMER_KEY,
//   kConsumerSecret: Config.REACT_NATIVE_NAVER_CONSUMER_SECRET,
//   kServiceAppName: "220cordncode",
// };

// const initials = Platform.OS === "ios" ? iosKeys : androidKeys;

class SocialMemberStore {
  constructor() {
    makeObservable(this);
  }

  @action addSocialMember = async (
    addSocialMemberStoreRequest: AddSocialMemberStoreRequest
  ) => {
    CommonStore.setLoading(true);
    void RecommenderStore.recommenderExist();
    const { socialTypeCode, accessToken, nonce } = addSocialMemberStoreRequest;
    const tempMember = await getTempMemberFromStorage();
    const addSocialMemberRequest: AddSocialMemberRequest = {
      ...addSocialMemberStoreRequest,
      tempMemberUuid: tempMember?.tempMemberUUID,
    };
    const addSocialMemberResponse = await addSocialMember(
      addSocialMemberRequest
    );
    if (addSocialMemberResponse.successOrNot === SuccessOrNot.Y) {
      if (addSocialMemberResponse.data?.voltMessage === "Success") {
        MainStore.setShowMemberJoinVoltSavePop(true);
      }
      const socialLoginRequest: SocialLoginRequest = {
        socialTypeCode,
        accessToken,
        nonce,
      };
      const socialLoginResponse = await socialLogin(socialLoginRequest);
      if (socialLoginResponse.data) {
        //NOTE : 둘러보기 없이 소셜가입을 시도한 경우 profile 설정을 새로 받도록 하기 위함. 추후 profile 컬럼이 nullable로 변경되면 필요 없는 로직.
        if (!tempMember?.profileUrl) {
          socialLoginResponse.data.profileUrl = undefined;
        }
        await setSessionUserInStorage({
          ...socialLoginResponse.data,
          loginType: socialTypeCode,
        } as UserInfo);
        await localStorage.setItem(LAST_LOGIN_TYPE, socialTypeCode);
        CommonStore.setLoading(false);
        //
        const gotoNextStep = async () => {
          if (socialLoginResponse.data?.nickname) {
            //NOTE : 둘러보기 후 회원으로 전환됨
            if (tempMember?.profileUrl) {
              //NOTE : 둘러보기 때 프로필 설정을 마쳤음
              const res = await updateMember({
                profileUrl: tempMember.profileUrl,
              });
              if (res.successOrNot === SuccessOrNot.Y) {
                await mergeAndSetSessionUserInStorage({
                  profileUrl: tempMember.profileUrl,
                });
              }
              if (
                RecommenderStore.recommenderYn === "Y" ||
                getStringRemoteConfig("show_recommend_input") === "true"
              ) {
                // console.log("#RecommenderScreen#");
                // reset("RecommenderScreen");
              } else {
                CommonStore.resetMainAfterSignUp(socialTypeCode);
              }
            } else if (tempMember?.profileImageFromGallary) {
              //NOTE : 둘러보기 때 갤러리 사진을 선택하여 프로필 설정을 마쳤음
              const filePath = await ProfileStore.uploadFileAndGetFilePath(
                tempMember?.profileImageFromGallary
              );
              const res = await updateMember({ profileUrl: filePath });
              if (res.successOrNot === SuccessOrNot.Y) {
                await mergeAndSetSessionUserInStorage({ profileUrl: filePath });
              }
              if (
                RecommenderStore.recommenderYn === "Y" ||
                getStringRemoteConfig("show_recommend_input") === "true"
              ) {
                // console.log("#RecommenderScreen-1#");
                // reset("RecommenderScreen");
              } else {
                CommonStore.resetMainAfterSignUp(socialTypeCode);
              }
            } else {
              //NOTE : 둘러보기 때 프로필 설정을 하지 않음
              if (
                RecommenderStore.recommenderYn === "Y" ||
                getStringRemoteConfig("show_recommend_input") === "true"
              ) {
                // console.log(
                //   `#("RecommenderScreen", { nextScreen: "ProfileSetting" })#`
                // );
                // reset("RecommenderScreen", { nextScreen: "ProfileSetting" });
              } else {
                goTo("/ProfileSetting");
              }
            }
            await removeTempMemberFromStorage();
          } else {
            goTo("/PreferenceScreen");
          }
        };
        void gotoNextStep();
      }
    } else {
      CommonStore.setLoading(false);

      const signUpFailMessage = (statusCode: string, errorMessage?: string) =>
        statusCode === StatusCode.EXIST_DUPLICATION_EMAIL && errorMessage
          ? t(`screen.join.infoAddAgree.duplicatedEmail.${errorMessage}`)
          : t(`screen.join.infoAddAgree.signUpfail`);

      CommonStore.setNotificationModalProps({
        isVisible: true,
        contents1: signUpFailMessage(
          addSocialMemberResponse.statusCode,
          addSocialMemberResponse.errorMessage
        ),
        onClickDefaultButton: () => CommonStore.resetNotificationModalProps(),
        onRequestClose: () => CommonStore.resetNotificationModalProps(),
      });
    }
  };

  @action signInWithNaver = async (code?: string, state?: string) => {
    if (code && state) {
      const result = await naverSocialLogin(code, state);
      if (result.statusCode === "OK" && result.successOrNot === "Y") {
        const { mobile, email, gender, birthYear, acessToken } = result.data;
        await localStorage.setItem("NAVER_ACCESSTOKEN", acessToken);

        if (mobile && email && gender && birthYear) {
          await this.getUser({
            socialTypeCode: SocialTypeCode.NAVER,
            accessToken: acessToken,
            emailAddress: email,
          });
        } else {
          await naverSocialDelete();
          CommonStore.setToastOption({
            show: true,
            message1: t("screen.social.naverRequireInfoCheck"),
          });
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("screen.social.naverError1"),
          message2: t("screen.social.naverError2"),
        });
      }
    } else {
      await localStorage.setItem("SIGNUPCODE", SignUpCode.NAVER);
      void naverAuthorize();
    }
  };

  // TODO 애플 로그인
  @action signInWithApple = async () => {
    // try {
    //   const { identityToken, nonce, user, email } = (await applePerformRequest()) as ApplePerformResponse;
    //   const credentialState = await getAppleCredentialStateForUser(user);
    //   if (credentialState !== AppleCredentialState.AUTHORIZED) {
    //     throw new Error();
    //   }
    //   await this.getUser({
    //     socialTypeCode: SocialTypeCode.APPLE,
    //     accessToken: identityToken,
    //     nonce,
    //     emailAddress: email,
    //   });
    // } catch (error) {
    //   CommonStore.setToastOption({
    //     show: true,
    //     message1: this.t("screen.social.appleError1"),
    //     message2: this.t("screen.social.appleError2"),
    //     // bottomOffset: 30,
    //   });
    // }
  };

  @action signInWithGoogle = async (code?: string) => {
    try {
      if (code) {
        const { id_token, access_token } = await getGoogleToken(code);
        const { id, email } = await getGoogleUser(access_token);
        await this.getUser({
          socialTypeCode: SocialTypeCode.GOOGLE,
          accessToken: id_token,
          emailAddress: email,
        });
      } else {
        await localStorage.setItem("SIGNUPCODE", SignUpCode.GOOGLE);
        googleAuthorize();
      }
    } catch (error) {
      CommonStore.setToastOption({
        show: true,
        message1: t("screen.social.googleError1"),
        message2: t("screen.social.googleError2"),
      });
    }
  };

  @action signInWithKakao = async (code?: string) => {
    if (code) {
      const accessToken = await getKakaoToken(code);
      if (accessToken) {
        const { email } = await getKakaoUser(accessToken);
        await this.getUser({
          socialTypeCode: SocialTypeCode.KAKAO,
          accessToken,
          emailAddress: email,
        });
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("screen.social.kakaoError1"),
          message2: t("screen.social.kakaoError2"),
        });
      }
    } else {
      await localStorage.setItem("SIGNUPCODE", SignUpCode.KAKAO);
      void kakaoAuthorize();
    }
  };

  @action signUpWithKakao = async (accessToken: string) => {
    const agreeTerms = await getKakaoServiceTerms(accessToken);
    const lastTerms = await getLastTerms();
    const kakaoAgreePromotion = agreeTerms.service_terms.find(
      (x) => x.tag === TermsTypeCode.PROMOTION
    )
      ? true
      : false;
    const isTermAgree = (termsTypeCode: string) => {
      return agreeTerms.service_terms.some(
        ({ tag }) =>
          KakaoTermTypeCode[termsTypeCode as keyof typeof KakaoTermTypeCode] ===
          tag
      );
    };
    const getTermsAgreeYn = (termsTypeCode: string) => {
      if (
        termsTypeCode === TermsTypeCode.PROMOTION_SMS ||
        termsTypeCode === TermsTypeCode.PROMOTION_EMAIL ||
        termsTypeCode === TermsTypeCode.PROMOTION_PUSH
      ) {
        return kakaoAgreePromotion
          ? AllowTypeCode.Y
          : isTermAgree(termsTypeCode)
          ? AllowTypeCode.Y
          : AllowTypeCode.N;
      } else {
        return isTermAgree(termsTypeCode) ? AllowTypeCode.Y : AllowTypeCode.N;
      }
    };
    const terms = lastTerms.map(({ termsId, termsTypeCode }) => ({
      termsId,
      termsAgreeYn: getTermsAgreeYn(termsTypeCode),
    })) as Terms[];
    const addSocialMemberStoreRequest: AddSocialMemberStoreRequest = {
      socialTypeCode: SocialTypeCode.KAKAO,
      accessToken,
      terms,
    };
    await this.addSocialMember(addSocialMemberStoreRequest);
  };

  @action getUser = async ({
    socialTypeCode,
    accessToken,
    nonce,
    emailAddress,
  }: GetUser) => {
    CommonStore.setLoading(true);

    const socialLoginRequest: SocialLoginRequest = {
      socialTypeCode,
      accessToken,
      nonce,
    };
    const { successOrNot, statusCode, data } = await socialLogin(
      socialLoginRequest
    );

    //로그인 타입 지정.
    if (data) {
      data.loginType = socialTypeCode;
    }

    //1년 이상 장기 미이용 고객일 경우 인증 로직 추가
    if (
      data &&
      successOrNot === SuccessOrNot.Y &&
      statusCode === StatusCode.LONG_TERM_UNUSED
    ) {
      const user: UserInfo = { ...data };
      user.isLongTermUnused = true;

      await removeTempMemberFromStorage();
      await setSessionUserInStorage(user);
      await localStorage.setItem(LAST_LOGIN_TYPE, socialTypeCode);

      goTo("/LongTermUnusedScreen", {
        state: { socialTypeCode: socialTypeCode },
      });

      return;
    }

    if (
      successOrNot === SuccessOrNot.Y &&
      data?.memberStateCode === MemberStateCode.NORMAL
    ) {
      const user: UserInfo = { ...data };
      await removeTempMemberFromStorage();
      await setSessionUserInStorage(user);
      await localStorage.setItem(LAST_LOGIN_TYPE, socialTypeCode);

      if (user) {
        if (!user.nickname) {
          goTo("/PreferenceScreen", { replace: true });
        } else if (!user.profileUrl) {
          goTo("/ProfileSetting", {
            replace: true,
            state: { hideBack: true },
          });
        } else if (user.nickname && user.profileUrl) {
          CommonStore.restMainAfterLogin(socialTypeCode);
          goRedirect();
        }
      }
    } else if (
      successOrNot === SuccessOrNot.Y &&
      data?.memberStateCode === MemberStateCode.DORMANT
    ) {
      const user: UserInfo = { ...data };
      await removeTempMemberFromStorage();
      await setSessionUserInStorage(user);
    } else {
      if (
        isNotNormalMember(data?.memberStateCode) ||
        isNotNormalMember(statusCode as StatusCode)
      ) {
        await MemberStore.notNormalMemberLogin({
          loginEmailAddress: emailAddress,
          socialLoginRequest,
        });
      } else {
        switch (statusCode) {
          case StatusCode.INCORRECT_USERNAME_OR_PASSWORD:
            socialTypeCode === SocialTypeCode.KAKAO
              ? await this.signUpWithKakao(accessToken)
              : goTo("/InfoAddAgree", {
                  replace: true,
                  state: {
                    email: data?.emailAddress,
                    accessToken,
                    socialTypeCode,
                    nonce,
                  },
                });
            break;
          case StatusCode.EXIST_DUPLICATION_EMAIL:
            data as SocialNotLoginResponseVO;
            goTo("/");
            if (data?.socialTypeCode) {
              CommonStore.setNotificationModalProps({
                isVisible: true,
                title: t("screen.notificationModal.label.alert"),
                contents1:
                  t("screen.socialAttractionModal.contents1", {
                    socialTypeCode: t(`common.social.${data.socialTypeCode}`),
                  }) +
                  "\n" +
                  t("screen.socialAttractionModal.contents2"),
                useTwoButton: true,
                defaultButtonText: t("screen.notificationModal.button.OK"),
                extraButtonText: t("screen.notificationModal.button.cancel"),
                onClickDefaultButton: () => {
                  switch (data.socialTypeCode) {
                    case SocialTypeCode.APPLE:
                      void this.signInWithApple();
                      break;
                    case SocialTypeCode.NAVER:
                      void this.signInWithNaver();
                      break;
                    case SocialTypeCode.KAKAO:
                      void this.signInWithKakao();
                      break;
                    case SocialTypeCode.GOOGLE:
                      void this.signInWithGoogle();
                      break;
                    case SocialTypeCode.EMAIL:
                      goTo("/LoginPresenter");
                      break;
                  }
                },
                onClickExtraButton: () =>
                  CommonStore.resetNotificationModalProps(),
                onRequestClose: () => CommonStore.resetNotificationModalProps(),
              });
            } else {
              CommonStore.setToastOption({
                show: true,
                message1: statusCode,
              });
            }
            break;
          default:
            goTo("/");
            CommonStore.setToastOption({
              show: true,
              message1: statusCode,
            });
            break;
        }
      }
    }
    CommonStore.setLoading(false);
  };
}

export default new SocialMemberStore();
