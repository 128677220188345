import { useCallback, useEffect, useRef, useState } from "react";
import PreferenceStore from "../../../store/PreferenceStore";
import { observer } from "mobx-react";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import SettingStore from "../../../store/SettingStore";
import AuthStore from "../../../store/AuthStore";
import CommonStore from "../../../store/CommonStore";
import RecommenderStore from "../../../store/RecommenderStore";
import { recommenderSave } from "../../../api/mission/api";
import { useTracker } from "../../../hooks/tracker";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicator";
import MemberStore from "../../../store/MemberStore";
import TagManagementStore from "../../../store/TagManagementStore";
import { useTranslation } from "react-i18next";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import MyInfoPreference from "./MyInfoPreference";
import HousematePreference from "./HousematePreference";
import RoomScalePreference from "./RoomScalePreference";
import HomeAppliancePreference from "./HomeAppliancePreference";
import UserStylePreference from "./UserStylePreference";
import classes from "./PreferenceMain.module.scss";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const PreferenceMain = observer(() => {
  const {
    clearAndRetrievePreferenceSteps,
    preferenceSteps,
    isMyInfoSelectionSatisfied,
    isHousemateSelectionSatisfied,
    isRoomScaleSelectionSatisfied,
    isHomeApplianceSelectionSatisfied,
    savePreferenceSelectionForTempMember,
    createPreferenceSelectionForMember,
    showFooter,
  } = PreferenceStore;
  const { t } = useTranslation();

  const { recommenderYn, recommenderNickname, setRecommenderNickname } =
    RecommenderStore;

  const { logout } = SettingStore;
  const { sessionUser } = AuthStore;
  const EXIT_TIME = 2000;
  const clickTime = useRef<number>(0);

  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const isLastStep = step === preferenceSteps.length - 1;
  const isFirstStep = step === 0;

  const { track } = useTracker();

  const navigation = useRef();
  const MyInfoPreferenceRef = useRef<any>(null);

  const handleGotoPrevStep = useCallback(() => {
    if (!isFirstStep) {
      setStep((step: number) => step - 1);
    } else {
      void logout();
      goTo("/LoginAttractScreen");
    }
  }, [isFirstStep, logout]);

  const handleGotoNextStep = async () => {
    MyInfoPreferenceRef.current?.handle();
    if (!isLastStep) {
      setStep((step: number) => {
        track(`complete_onboarding_step${step + 1}`, {});
        return step + 1;
      });
      return;
    }
    const savePreferenceSelection = sessionUser?.memberUuid
      ? createPreferenceSelectionForMember
      : savePreferenceSelectionForTempMember;

    setLoading(true);
    await savePreferenceSelection().then(() => {
      if (sessionUser?.memberUuid) {
        void MemberStore.saveRankingPosterStyle();
        void TagManagementStore.getInterestedTagList(sessionUser?.memberUuid);
      }
    });
    setLoading(false);

    track("complete_onboarding_step5", {});
    track("complete_onboarding", {});

    if (
      (getStringRemoteConfig("show_recommend_input") === "true" ||
        recommenderYn === "Y") &&
      recommenderNickname
    ) {
      const result = await recommenderSave(recommenderNickname);
      result && setRecommenderNickname("");
    }

    MemberStore.clear();
    goTo("/ProfileSetting", { replace: true });
  };

  const isSelectionMinMaxSatisfied = () => {
    let satisfied = preferenceSteps.length > 0;
    if (preferenceSteps.length > 0) {
      switch (preferenceSteps[step]) {
        case PreferenceTypeCode.MY_INFO:
          satisfied = isMyInfoSelectionSatisfied;
          break;
        case PreferenceTypeCode.HOUSEMATE:
          satisfied = isHousemateSelectionSatisfied;
          break;
        case PreferenceTypeCode.ROOM_SCALE:
          satisfied = isRoomScaleSelectionSatisfied;
          break;
        case PreferenceTypeCode.HOME_APPLIANCE:
          satisfied = isHomeApplianceSelectionSatisfied;
          break;
      }
    }
    return satisfied;
  };

  const animateOption = {
    cardStyleInterpolator: ({ current, layouts }: any) => {
      return {
        cardStyle: {
          opacity: current.progress,
        },
      };
    },
  };

  // TODO
  // const handleBackPress = useCallback(() => {
  //   const diffSec = Math.floor(Date.now() - clickTime.current);

  //   if (!isFirstStep) void handleGotoPrevStep();
  //   else if (navigation.current.canGoBack()) void navigation.current.goBack();
  //   else if (diffSec > EXIT_TIME) {
  //     CommonStore.setToastOption({
  //       show: true,
  //       autoHide: true,
  //       message1: t("common.message.backButtonExit"),
  //       durationTime: EXIT_TIME,
  //     });
  //     clickTime.current = Date.now();
  //   } else {
  //     BackHandler.exitApp();
  //   }
  //   return true;
  // }, [isFirstStep, handleGotoPrevStep, t]);

  // useEffect(() => {
  //   const backHandler = BackHandler.addEventListener(
  //     "hardwareBackPress",
  //     handleBackPress
  //   );
  //   return () => backHandler.remove();
  // }, [step, handleBackPress]);

  useEffect(() => {
    void clearAndRetrievePreferenceSteps();
  }, [clearAndRetrievePreferenceSteps]);

  useEffect(() => {
    if (preferenceSteps.length) {
      // navigation.current.navigate(preferenceSteps[step]);
    }
  }, [step, preferenceSteps]);

  useEffect(() => {
    if (
      preferenceSteps.length &&
      preferenceSteps[step] === PreferenceTypeCode.HOUSEMATE &&
      PreferenceStore.selectedHousematePreferenceListCodes.size == 0
    ) {
      track("start_onboarding", {});
    }
  }, [preferenceSteps, step, track]);

  const renderFooter = () => {
    return (
      <div className={classes.footer_wrapper}>
        <div className={classes.footer}>
          <div
            className={clsx(classes.footer_btn, classes.footer_active_btn)}
            onClick={handleGotoPrevStep}
            aria-label={`previous step`}
          >
            <span
              className={clsx(
                classes.footer_btn_text,
                classes.footer_active_btn_text
              )}
            >
              {t("common.label.prev")}
            </span>
          </div>
          <div className={classes.pagination} aria-label={"Pagination"}>
            {preferenceSteps.map((value, index) => (
              <div
                className={clsx(
                  classes.page,
                  index === step ? classes.active_page : classes.inactive_page
                )}
                key={`pagination dot-${index}`}
              />
            ))}
          </div>
          <TouchableWithAsyncTask
            className={clsx(
              classes.footer_btn,
              isSelectionMinMaxSatisfied() ? classes.footer_active_btn : false
            )}
            onPress={handleGotoNextStep}
            disabled={!isSelectionMinMaxSatisfied()}
            aria-label={`next step`}
          >
            <span
              className={clsx(
                classes.footer_btn_text,
                isSelectionMinMaxSatisfied()
                  ? classes.footer_active_btn_text
                  : false
              )}
            >
              {t("common.label.next")}
            </span>
          </TouchableWithAsyncTask>
        </div>
      </div>
    );
  };

  const renderScreen = () => {
    switch (preferenceSteps[step]) {
      case PreferenceTypeCode.MY_INFO:
        return (
          <MyInfoPreference
            ref={MyInfoPreferenceRef}
            nextHandler={() => {
              void handleGotoNextStep();
            }}
          />
        );
      case PreferenceTypeCode.HOUSEMATE:
        return <HousematePreference />;
      case PreferenceTypeCode.ROOM_SCALE:
        return <RoomScalePreference />;
      case PreferenceTypeCode.HOME_APPLIANCE:
        return <HomeAppliancePreference />;
      case PreferenceTypeCode.USER_STYLE:
        return <UserStylePreference />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <div className={classes.preference_main}>
        <div className={classes.preference_contents}>{renderScreen()}</div>
        {showFooter && renderFooter()}
      </div>
      {loading && <CustomActivityIndicator />}
    </>
  );
});

export default PreferenceMain;
