import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import CloseIcon from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  contentsHTML?: () => string | JSX.Element | undefined; // 타입 변경
  contents1: string;
  contents2?: string;
  defaultButtonText?: string;
  extraButtonText?: string;
  isVisible: boolean;
  isBlack?: boolean;
  useTwoButton?: boolean;
  onRequestClose?: () => void;
  onClickDefaultButton?: () => void;
  onClickExtraButton?: () => void;
  modalCloseCallback?: () => void;
  textAlign?: "auto" | "left" | "right" | "center" | "justify";
  modalWidth?: number;
  children?: React.ReactNode;
  // handleClose: () => void; // handleClose prop 추가
  // handleOnCloseCallback: () => void;
}

const NotificationModalWithHideCallback: React.FC<Props> = ({
  title,
  contentsHTML,
  contents1,
  contents2,
  defaultButtonText,
  extraButtonText,
  isVisible,
  isBlack,
  useTwoButton,
  onRequestClose,
  onClickDefaultButton,
  onClickExtraButton,
  modalCloseCallback,
  textAlign,
  modalWidth,
  children
}) => {
  const { t } = useTranslation();

  const dismiss = () => {
    if (onRequestClose !== undefined) onRequestClose();
  };

  const onDefaultButtonClicked = () => {
    if (onClickDefaultButton !== undefined) onClickDefaultButton();
    else dismiss();
  };

  const onExtraButtonClicked = () => {
    if (onClickExtraButton !== undefined) onClickExtraButton();
  };

  const onModalHideCallback = () => {
    if (modalCloseCallback !== undefined) modalCloseCallback();
  };

  return (
    <Modal
      // style={{ zIndex: 9999 }}
      isOpen={isVisible} // 변경된 prop: isVisible 대신 isOpen 사용
      onRequestClose={dismiss} // 변경된 prop: onBackButtonPress 대신 onRequestClose 사용
      onAfterClose={onModalHideCallback} // 변경된 이벤트: onModalHide 대신 onAfterClose 사용
      contentLabel="Notification Modal"
    >
      <div className="notification-modal" style={{ width: modalWidth ? modalWidth : 265 }}>
        <div className="modal-header">
          <h2 className="header-text" aria-label="Title">
            {title && title !== undefined ? title : t("screen.notificationModal.label.alert")}
          </h2>
          <button className="close-button" onClick={dismiss} aria-label="Close">
            <CloseIcon />
          </button>
        </div>
        {contents1.length !== 0 && (
          <div className="modal-body">
            <p className="text-0" aria-label="Contents1">
              {contents1}
            </p>
            {contents2 && contents2 !== undefined && (
              <p className="text-1" aria-label="Contents2">
                {contents2}
              </p>
            )}
          </div>
        )}
        {contentsHTML && (
          <div className="modal-body">
            <div className="text-0" aria-label="contentsHTML">
              {contentsHTML()}
            </div>
          </div>
        )}
        {children && <>{children}</>}
        {true === true ? (
          // {useTwoButton === true ? (
          <div className="modal-footer-two">
            <button
              className={`button-two ${isBlack ? "black" : "gray"} left`}
              onClick={onExtraButtonClicked}
              aria-label="Left button"
            >
              <span className="footer-button-text">{extraButtonText ? extraButtonText : t("screen.notificationModal.button.close")}</span>
            </button>
            <button
              className="button-two black right"
              onClick={onDefaultButtonClicked}
              aria-label="Right button"
            >
              <span className="footer-button-text">{defaultButtonText ? defaultButtonText : t("screen.notificationModal.button.close")}</span>
            </button>
          </div>
        ) : (
          <div className="modal-footer">
            <button
              className="button black"
              onClick={onDefaultButtonClicked}
              aria-label="Close button"
            >
              <span className="footer-button-text">{defaultButtonText ? defaultButtonText : t("screen.notificationModal.button.close")}</span>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NotificationModalWithHideCallback;