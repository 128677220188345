// 현재 미사용
import { MutableRefObject, createRef } from "react";
import { NavigateOptions, To } from "react-router-dom";
import { REDIRECT_PATH } from "../../constants/Storage";

export type NavigateFunction = (
  path: To,
  NavigateOptions?: NavigateOptions
) => void;

export type NavigateRef = MutableRefObject<null | {
  goTo: NavigateFunction;
  goBack: () => void;
  goBackTwice: () => void;
}>;

export const navigateRef: NavigateRef = createRef();

export const goTo: NavigateFunction = (to, options) => {
  void navigateRef.current?.goTo(to, options);
};
export const goBack = () => {
  void navigateRef.current?.goBack();
};
export const goBackTwice = () => {
  void navigateRef.current?.goBackTwice();
};

export const canGoBack = () => {
  return window.history.length > 1;
};

export const goRedirect = () => {
  let redirect = localStorage.getItem(REDIRECT_PATH);
  goTo(redirect || "/", { replace: true });
  localStorage.removeItem(REDIRECT_PATH);
};
