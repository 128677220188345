import React, { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as KakaoIcon } from "../../assets/icons/icon_login_kakao.svg";
import { ReactComponent as NaverIcon } from "../../assets/icons/btn_sns_login_Naver.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/btn_sns_login_google.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/icon_login_apple.svg";
import { useTranslation } from "react-i18next";
import { SignUpCode } from "../../constants/SignUp.enum";
// import { navigate } from "../../navigation/NavigationFunction";
import TabStore from "../../store/TabStore";
import { observer } from "mobx-react";
import SocialMemberStore from "../../store/SocialMemberStore";
import { useTracker, withTrack } from "../../hooks/tracker";
import MainStore from "../../store/MainStore";
import { useLocation } from "react-router-dom";
import { Platform, PlatformTypes } from "../../native/platform";
import "./JoinModal.scss";
import { REDIRECT_PATH } from "../../constants/Storage";
import { goTo } from "../../hooks/navigate/NavigateFunction";

const JoinModal = observer(() => {
  const location = useLocation();
  const { t } = useTranslation();
  const { setTabBarDisplay } = TabStore;
  const {
    // signInWithApple,
    signInWithGoogle,
    signInWithNaver,
    signInWithKakao,
  } = SocialMemberStore;

  const { track } = useTracker();

  const clickSignUpBtn = (sort: string) => {
    // navigation.goBack();
    MainStore.setShowJoinModal(false);

    switch (sort) {
      case SignUpCode.NAVER:
        void signInWithNaver();
        break;
      case SignUpCode.GOOGLE:
        void signInWithGoogle();
        break;
      case SignUpCode.KAKAO:
        void signInWithKakao();
        break;
      // case SignUpCode.APPLE:
      //   void signInWithApple();
      //   break;
      case SignUpCode.EMAIL:
        // navigate("JoinWithEmailScreen");
        goTo("/JoinWithEmailScreen");
        break;
    }

    track("click_signup", {
      method: sort,
      component: "JoinModal",
    });
  };

  const clickEmailSignInBtn = () => {
    // navigation.goBack();
    // navigate("LoginScreen");
    MainStore.setShowJoinModal(false);
    goTo("/LoginPresenter");
  };

  // React.useEffect(() => {
  //   setTabBarDisplay("none");
  //   return () => {
  //     setTabBarDisplay(undefined);
  //   };
  // }, [setTabBarDisplay]);

  React.useEffect(() => {
    track("view_signup_page", {
      is_modal: true,
      component: "JoinModal",
    });
    localStorage.setItem(REDIRECT_PATH, location.pathname);
  }, [location.pathname, track]);

  const goBack = () => {
    MainStore.setShowJoinModal(false);
  };

  return (
    <div id="JoinModal" className="join_guidance_wrap">
      <div className="modal_dim" onClick={goBack} />
      <div
        className="list_wrap"
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      >
        <div className="close_btn">
          <div onClick={goBack}>
            <CloseIcon />
          </div>
        </div>

        <div className="warning_text_wrap">
          <div className="warning_text">
            {t("screen.joinModal.label.warning1")}
          </div>
          <div className="warning_text">
            {t("screen.joinModal.label.warning2")}
          </div>
        </div>

        <div className="join_text_wrap">
          <div className="join_text">{t("screen.joinModal.label.join")}</div>
        </div>

        {/* <div className="join_popover">
          <div className="join_popover_inner">
            <div className="triangle-wrapper"><div className="triangle" /></div>
            <div className="join_popover_text1">
              {t("screen.joinModal.label.join_popover_text1")}
              <div className="join_popover_text2">
                {t("screen.joinModal.label.join_popover_text2")}
              </div>
            </div>
          </div>
        </div> */}

        <div className="kakao_btn_wrap">
          <div
            className="kakao"
            onClick={() => clickSignUpBtn(SignUpCode.KAKAO)}
          >
            <KakaoIcon style={{ width: "30px", height: "30px" }} />
            <div className="text_btn">{t("screen.joinModal.label.kakao")}</div>
          </div>
          {/* {Platform.OS === PlatformTypes.IOS_APP ||
            (Platform.OS === PlatformTypes.IOS_WEB && ( */}
          {/* <div
            className="apple"
            onClick={() => clickSignUpBtn(SignUpCode.APPLE)}
          >
            <AppleIcon />
            <div className="text_btn_white">
              {t("screen.joinModal.label.apple")}
            </div>
          </div> */}
          {/* ))} */}
        </div>

        <div className="middle_bar">
          {/* <div className="middle_line" /> */}
          <div className="middle_text">
            {t("screen.joinModal.label.middleBar")}
          </div>
          {/* <div className="middle_line" /> */}
        </div>

        <div className="sns">
          <div
            className="sns_img"
            onClick={() => clickSignUpBtn(SignUpCode.NAVER)}
          >
            <NaverIcon />
          </div>
          <div
            className="sns_img"
            onClick={() => clickSignUpBtn(SignUpCode.GOOGLE)}
          >
            <GoogleIcon />
          </div>
        </div>

        <div className="bottom_area">
          <div
            className="bottom_btn  email_btn"
            onClick={() => clickSignUpBtn(SignUpCode.EMAIL)}
          >
            <div className="bottom_text">
              {t("screen.joinModal.label.bottom1")}
            </div>
          </div>
          {/* <div className="bottom_area_line" /> */}
          <div className="bottom_btn" onClick={() => clickEmailSignInBtn()}>
            <div className="bottom_text">
              {t("screen.joinModal.label.bottom2")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default JoinModal;
