import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";

import MainStore from "../../store/MainStore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreRenderMainStore from "../../store/main/PreRenderMainStore";
import { getDateStr, getDdayFromToday, isPast } from "../../utils/datetime";
import {
  MainItem,
  PopularQna,
  PopularRanking,
  PopularTag,
  Post,
} from "../../api/feed/model";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import Rating from "../rating/Rating";
import MainBanner from "../main/presenter/MainBanner";
// import MainMallNotice from "./presenter/MainMallNotice";
import MainProfile from "../main/presenter/MainProfile";
import MainTextBanner from "../main/presenter/MainTextBanner";
import MainSectionItem from "../main/presenter/MainSectionItem";
import AuthStore from "../../store/AuthStore";
import MarketingAgreeModal from "../../components/modals/MarketingAgreeModal";
import { getAlarmDefaultSetting } from "../../api/setting/alarm/api";
import { AllowTypeCode } from "../../constants/CommonCode.enum";
// import { sendNonIdentifyInfo, updateMember } from "../../api/member/api";
import { updateMember } from "../../api/member/api";
import {
  NOTICE_BANNER,
  POPUP_BANNER,
  PUSH_ALLOW_YN,
  MAIN_MALL_NOTICE,
} from "../../constants/Storage";
import { AlarmSettingList } from "../../api/setting/alarm/model";
import { getSessionUserFromStorage } from "../../service/Session.service";
import { BadgeCode, IsMember, SearchType } from "../../constants/Badge.enum";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import dayjs from "dayjs";
import BadgeStore from "../../store/BadgeStore";
import { handleTopButtonScroll } from "../../utils/common";
// import NotificationStore from "../../store/NotificationStore";
import RecommenderStore from "../../store/RecommenderStore";
import { useTracker } from "../../hooks/tracker";
import PopularPost from "../main/popularPost/PopularPost";
import { MainFeedTypeCode } from "../../constants/FeedType.enum";
import { getTempMemberFromStorage } from "../../service/Preference.service";
import { getTempMember, updateTempMember } from "../../api/tempMember/api";
import { TempMember } from "../../api/tempMember/model";
// import { getDevicePushToken } from "../../native/deviceToken/native";
import { sendActHistory } from "../../api/data/api";
import { ActHistoryRequest } from "../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../constants/ActHistory";
// import VoltModal from "./presenter/VoltModal";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import { track } from "../../hooks/tracker/TrackFunction";
import RemoteConfigStore from "../../store/RemoteConfigStore";
import CommonStore from "../../store/CommonStore";
import { ReactComponent as Spinner } from "../../assets/animations/spinner.svg";
import { useWindowScroll } from "react-use";
import { PullToRefresh, List } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { PullStatus } from "antd-mobile/es/components/pull-to-refresh";
import { ReactComponent as DarkSpinner } from "../../assets/animations/darkSpinner.svg";
import MainLayout from "../main/MainLayout";
import MainPopupBanner from "../main/presenter/MainPopupBanner";
import VoltModal from "../main/presenter/VoltModal";
// import MainTutorial from "../main/presenter/MainTutorial";
import SearchStore from "../../store/SearchStore";
import "./HomeScreen.scss";
import { ReactComponent as MainProfileAI } from "../../assets/icons/main/profile_title.svg";

enum MainPopupType {
  PUSH_ALLOW = "PUSH_ALLOW",
  TEXT_NOTICE = "TEXT_NOTICE",
  BOTTOM_BANNER = "BOTTOM_BANNER",
  MEMBER_JOIN_VOLT_SAVE = "MEMBER_JOIN_VOLT_SAVE",
  MAIN_MALL_NOTICE = "MAIN_MALL_NOTICE",
}

const HomeScreen = observer(() => {
  const {
    mainList,
    isLoadingMore,
    canLoadMore,
    showBottomSheet,
    showBottomSheetOnce,
    setShowBottomSheetOnce,
    setIsLoadingMore,
    getMoreMain,
  } = MainStore;

  const { sessionUser, showProfileBanner, setShowProfileBanner, tempMember } =
    AuthStore;
  // const { initialNotificaiton } = NotificationStore;

  const [isModalVisible, setModalVisible] = useState(false); // 푸시 동의 팝업 노출 제어
  const [showMemberJoinVoltSaveModal, setShowMemberJoinVoltSaveModal] =
    useState(false);
  const [showMainMallNotice, setShowMainMallNotice] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const [isShowTutorial, setIsShowTutorial] = useState<boolean>(false);
  const [tracker] = useState(useTracker());

  const [mainPopupList, setMainPopupList] = useState<any[]>([
    { type: MainPopupType.PUSH_ALLOW, isShow: false },
    { type: MainPopupType.TEXT_NOTICE, isShow: false },
    { type: MainPopupType.MAIN_MALL_NOTICE, isShow: false },
    { type: MainPopupType.BOTTOM_BANNER, isShow: false },
    { type: MainPopupType.MEMBER_JOIN_VOLT_SAVE, isShow: false },
  ]);

  const { x, y } = useWindowScroll();

  const bannerRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.MAIN,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  useEffectOnlyOnce(() => {
    void settingMainPopupList();
    SearchStore.clear();
  });

  const changeMainPopupList = (type: MainPopupType, val: boolean) => {
    const temp = mainPopupList;
    temp.forEach((x) => {
      if (x.type === type) x.isShow = val;
    });
    setMainPopupList(temp);
  };

  const settingMainPopupList = async () => {
    await Promise.all([
      pushAllowYnCheck(),
      textNoticeCheck(),
      // mainMallNoticeCheck(),
      bottomBannerCheck(),
    ]).finally(() => {
      if (MainStore.showMemberJoinVoltSavePop) {
        changeMainPopupList(MainPopupType.MEMBER_JOIN_VOLT_SAVE, true);
        MainStore.setShowMemberJoinVoltSavePop(false);
      }
      setTimeout(() => {
        handleMainPopup();
      }, 500);
    });
  };

  const handleMainPopup = () => {
    if (mainPopupList.find((x) => x.type == MainPopupType.PUSH_ALLOW).isShow) {
      setModalVisible(true);
    }
    if (mainPopupList.find((x) => x.type == MainPopupType.TEXT_NOTICE).isShow) {
      MainStore.setShowTextBannerModal(true);
    }
    if (
      mainPopupList.find((x) => x.type == MainPopupType.MAIN_MALL_NOTICE).isShow
    ) {
      setShowMainMallNotice(true);
    }
    if (
      mainPopupList.find((x) => x.type == MainPopupType.BOTTOM_BANNER).isShow
    ) {
      MainStore.setShowBottomSheet(true);
    }
    if (
      mainPopupList.find((x) => x.type == MainPopupType.MEMBER_JOIN_VOLT_SAVE)
        .isShow
    ) {
      setShowMemberJoinVoltSaveModal(true);
    }
  };

  const textNoticeCheck = async () => {
    const today = dayjs().format("YYYYMMDD");

    const getBannerContents = () => {
      void MainStore.getBannerContents(
        BannerTypeCodes.MAIN_POP_UP_NOTIFICATION_BANNER
      ).then(() => {
        if (MainStore.textBanner) {
          changeMainPopupList(MainPopupType.TEXT_NOTICE, true);
        }
      });
    };

    const noticeBannerStr = await localStorage.getItem(NOTICE_BANNER);
    if (noticeBannerStr) {
      const noticeBanner = JSON.parse(noticeBannerStr);
      const showNoticeBannerYn = noticeBanner?.yn;
      const showNoticeBannerLastDay = noticeBanner?.lastDay;
      if (showNoticeBannerYn == "N") {
        if (today !== showNoticeBannerLastDay) {
          // 마지막으로 '안보기' 한 날짜가 오늘이 아니라면
          const noticeItemStr = JSON.stringify({ yn: "Y", lastDay: today });
          void localStorage.setItem(NOTICE_BANNER, noticeItemStr);
          getBannerContents();
        }
      } else {
        getBannerContents();
      }
    } else {
      getBannerContents();
    }
  };

  // const mainMallNoticeCheck = async () => {
  //   const temp: string = RemoteConfigStore.main_mall_notice;
  //   if (temp) {
  //     const dataList: { isShow: string; date: string; contents: string } =
  //       JSON.parse(temp ?? {});
  //     const memberUuid = AuthStore.sessionUser?.memberUuid;
  //     const createdDateTime = dayjs(AuthStore?.sessionUser?.createdDateTime);
  //     const date = dayjs(dataList.date);

  //     if (
  //       dataList.isShow === "true" &&
  //       memberUuid &&
  //       createdDateTime.isBefore(date)
  //     ) {
  //       const result = await localStorage.getItem(
  //         `${MAIN_MALL_NOTICE}_${memberUuid}`
  //       );
  //       if (result !== "Y") {
  //         changeMainPopupList(MainPopupType.MAIN_MALL_NOTICE, true);
  //       }
  //     }
  //   }
  // };

  const bottomBannerCheck = async () => {
    const today = dayjs().format("YYYYMMDD");

    const getBannerContents = () => {
      void MainStore.getBannerContents(BannerTypeCodes.MAIN_POP_UP_BANNER).then(
        () => {
          if (MainStore.popupBannerList?.length > 0) {
            changeMainPopupList(MainPopupType.BOTTOM_BANNER, true);
          }
        }
      );
    };

    const popupBannerStr = await localStorage.getItem(POPUP_BANNER);

    if (popupBannerStr) {
      const popupBanner = JSON.parse(popupBannerStr);
      const showPopupBannerYn = popupBanner?.yn;
      const showPopupBannerLastDay = popupBanner?.lastDay;
      if (showPopupBannerYn == "N") {
        if (today !== showPopupBannerLastDay) {
          // 마지막으로 '안보기' 한 날짜가 오늘이 아니라면
          const popupItemStr = JSON.stringify({ yn: "Y", lastDay: today });
          void localStorage.setItem(POPUP_BANNER, JSON.stringify(popupItemStr));
          getBannerContents();
        }
      } else {
        getBannerContents();
      }
    } else {
      getBannerContents();
    }
  };

  const pushAllowYnCheck = async () => {
    const sessionUser = await getSessionUserFromStorage();
    if (sessionUser?.memberUuid) {
      const storagePushAllowYn = (await localStorage.getItem(
        `${PUSH_ALLOW_YN}_${sessionUser.memberUuid}`
      )) as AllowTypeCode;
      if (!storagePushAllowYn) {
        const { pushAllowYn } =
          (await getAlarmDefaultSetting()) as AlarmSettingList;
        if (pushAllowYn === AllowTypeCode.Y) {
          await localStorage.setItem(
            `${PUSH_ALLOW_YN}_${sessionUser.memberUuid}`,
            pushAllowYn
          );
        } else if (!pushAllowYn || pushAllowYn === AllowTypeCode.N) {
          changeMainPopupList(MainPopupType.PUSH_ALLOW, true);
        }
      }
    } else {
      // const tempSessionUser = await getTempMemberFromStorage();
      // if (tempSessionUser?.tempMemberUUID) {
      //   const storagePushAllowYn = (await localStorage.getItem(
      //     `${PUSH_ALLOW_YN}_${tempSessionUser.tempMemberUUID}`
      //   )) as AllowTypeCode;
      //   if (!storagePushAllowYn) {
      //     const { pushAllowYn } = (await getTempMember(
      //       tempSessionUser.tempMemberUUID
      //     )) as TempMember;
      //     if (pushAllowYn === AllowTypeCode.Y) {
      //       await localStorage.setItem(
      //         `${PUSH_ALLOW_YN}_${tempSessionUser.tempMemberUUID}`,
      //         pushAllowYn
      //       );
      //     } else if (!pushAllowYn || pushAllowYn === AllowTypeCode.N) {
      //       changeMainPopupList(MainPopupType.PUSH_ALLOW, true);
      //     }
      //   }
      // }
    }
  };

  const updateMemberPushAllowYn = async (pushAllowYn: AllowTypeCode) => {
    const sessionUser = await getSessionUserFromStorage();
    if (sessionUser?.memberUuid) {
      await updateMember({ pushAllowYn });
      await localStorage.setItem(
        `${PUSH_ALLOW_YN}_${sessionUser.memberUuid}`,
        pushAllowYn
      );
    } else {
      // const tempSessionUser = await getTempMemberFromStorage();
      // const { token } = await getDevicePushToken();
      // if (tempSessionUser?.tempMemberUUID) {
      //   const request = {
      //     tempMemberUuid: tempSessionUser.tempMemberUUID,
      //     tempMemberId: tempSessionUser.tempMemberId,
      //     pushAllowYn: pushAllowYn,
      //     devicePushToken: token,
      //   };
      //   await updateTempMember(request);
      //   await localStorage.setItem(
      //     `${PUSH_ALLOW_YN}_${tempSessionUser.tempMemberUUID}`,
      //     pushAllowYn
      //   );
      // }
    }
  };

  const initBadges = useCallback(() => {
    void BadgeStore.getMemberBadges({
      searchType: SearchType.ALL,
      isMember: IsMember.Y,
    });
  }, []);

  // const initAppMainBySavedData = useCallback(async () => {
  //   // 앱과 다르게 웹에서는 새로고침 시 스토어 유지가 안돼 해당 메서드 사용 시 매인 베너 및 인기 포스트 미노출 이슈가 있어 미사용
  //   await PreRenderMainStore.initFlatListData().then((result) => {
  //     MainStore.setMainList(result);
  //   });

  //   MainStore.setCurrentNumberOfList(1);
  //   MainStore.setIsLoadingMore(false);
  //   PreRenderMainStore.clear();
  // }, []);

  const initMainByApiCall = useCallback((memberUuid: string | undefined) => {
    void Promise.all([
      PreRenderMainStore.initFlatListData(),
      PreRenderMainStore.initMain(memberUuid),
    ])
      .then((result) => {
        const mainItems = result[0];
        MainStore.setMainList(mainItems ?? []);
        MainStore.setCurrentNumberOfList(1);
      })
      .finally(() => {
        PreRenderMainStore.clear();
      });
  }, []);

  useEffect(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (!isLoadingMore) {
        setIsLoadingMore(true);
        void getMoreMain();
      }
    }
  }, [y]);

  const onRefresh = () => {
    MainStore.setIsRefreshing(true);

    initBadges();
    initMainByApiCall(sessionUser?.memberUuid ?? "");

    MainStore.setIsLoadingMore(false);
    MainStore.setInitLoaded(false);
    MainStore.setIsRefreshing(false);
    MainStore.postMap.clear();
    MainStore.loggingCompleteSet.clear();

    tracker.track("view_home", {});
  };

  const statusRecord: Record<PullStatus, string> = {
    pulling: "pulling",
    canRelease: "canRelease",
    refreshing: "refreshing...",
    complete: "complete",
  };

  /** 메인 하단 팝업 배너 끄지 않고 다른 화면으로 갔다가 돌아올 경우를 위해 존재하는 코드입니다. */
  // useFocusEffect(
  //   useCallback(() => {
  //     return () => {
  //       if (showBottomSheet) setShowBottomSheet(false);
  //     };
  //   }, [showBottomSheet, setShowBottomSheet])
  // );

  // useEffect(() => {

  //   handleTopButtonScroll(
  //     y,
  //     () => {
  //       setShowTopButton(true);
  //       setProfileToolTipFlag(false);
  //     },
  //     () => {
  //       setShowTopButton(false);
  //     }
  //     // ref
  //   );

  //   // 툴팁 노출 제어
  //   CommonStore.disabledMainTooltip();
  // }, [y]);

  useEffect(() => {
    const heightOfMainBanner: number = bannerRef.current?.offsetHeight || 0;
    const heightOfMainProfile: number = profileRef.current?.offsetHeight || 0;
    const currentHeightOfScroll = y;

    if (showProfileBanner && currentHeightOfScroll < heightOfMainBanner) {
      setShowProfileBanner(false);
    }

    if (
      !showProfileBanner &&
      currentHeightOfScroll > heightOfMainBanner + heightOfMainProfile
    ) {
      setShowProfileBanner(true);
    }

    // 툴팁 노출 제어
    CommonStore.disabledMainTooltip();
  }, [y]);

  const renderListHeader = useMemo(() => {
    return (
      <div className="renderListHeader">
        <MainBanner bannerRef={bannerRef} />
        <MainProfile isLoggedIn={isLoggedIn} profileRef={profileRef} />
        <PopularPost />
      </div>
    );
  }, [isLoggedIn]);

  // const initRecommenderMission = useCallback(async () => {
  //   await RecommenderStore.recommenderExist();
  //   if (RecommenderStore.recommenderYn === "Y") {
  //     if (!sessionUser?.memberUuid) {
  //       setTimeout(() => {
  //         // NotificationStore.navigateByInitialNotification();
  //       }, 10);
  //     }
  //     RecommenderStore.setRecommenderYn("N");
  //   }
  // }, [sessionUser]);

  useEffect(() => {
    initBadges();
    // void sendNonIdentifyInfo();
    void MainStore.trackAllUserProperties();
  }, [initBadges]);

  useEffect(() => {
    if (sessionUser?.memberUuid) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [sessionUser?.memberUuid]);

  useEffect(() => {
    if (mainList.length === 0) {
      initMainByApiCall("");
      MainStore.setIsLoadingMore(false);
    }
  }, [initMainByApiCall]);
  /* eslint-enable */

  // useEffect(() => {
  //   // if (initialNotificaiton && initialNotificaiton.link && initialNotificaiton.link.indexOf("nickname") > -1) {
  //   //   void initRecommenderMission();
  //   // } else if (initialNotificaiton) {
  //   //   setTimeout(() => {
  //   //     NotificationStore.navigateByInitialNotification();
  //   //   }, 10);
  //   // }
  //   if (initialNotificaiton) {
  //     setTimeout(() => {
  //       NotificationStore.navigateByInitialNotification();
  //     }, 10);
  //   }
  // }, [initialNotificaiton, sessionUser, initRecommenderMission]);

  // useEffect(() => {
  //   // [5950] 툴팁 노출 제어
  //   if (isFocused) {
  //     void cache.get("isShownMainTutorial").then((isShownTutorial) => {
  //       if (isShownTutorial && isShownTutorial === "true") {
  //         void cache.get("showMainTooltip2").then((showMainTooltip2) => {
  //           if (!showMainTooltip2 || showMainTooltip2 !== "SUCCESS") {
  //             CommonStore.setIsShowMainTooltip2(true);
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     CommonStore.disabledMainTooltip();
  //   }
  // }, [isFocused]);

  
  // [6396]web main tutorial 제거
  // useEffect(() => {
  //   const checkTutorial = async () => {
  //     const result = await localStorage.getItem("isShownMainTutorial");

  //     if (result && result === "false") {
  //       setIsShowTutorial(false);
  //     } else {
  //       setIsShowTutorial(true);
  //     }
  //   };

  //   void checkTutorial();
  // }, []);

  return (
    <MainLayout>
      <div id="HomeScreen">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            onRefresh();
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <div className="main">
            <div className="body">
              <div className="contents_wrap">
                {renderListHeader}
                <div key={"MAIN_POST_AI"}>
                  <div className="bg_popular_post">
                    <div className="img_popular_post">
                      <MainProfileAI width={83} />
                    </div>
                  </div>
                </div>
                {mainList &&
                  mainList.map((item: any, index: number) => {
                    return (
                      <div
                        className={"rowRenderer " + item.sort}
                        style={{ display: !item ? "none" : "" }}
                      >
                        <MainSectionItem
                          item={item}
                          index={index}
                          visible={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </PullToRefresh>
      </div>

      {/* 팝업 순서대로 나열 */}
      {isModalVisible && (
        <MarketingAgreeModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          onAgreeBtnPress={() => {
            tracker.track("click_push_allow_button", {});
            void updateMemberPushAllowYn(AllowTypeCode.Y);
            changeMainPopupList(MainPopupType.PUSH_ALLOW, false);
            //
          }}
          onDisagreeBtnPress={() => {
            void updateMemberPushAllowYn(AllowTypeCode.N);
            changeMainPopupList(MainPopupType.PUSH_ALLOW, false);
          }}
          onCloseCallback={() => {
            handleMainPopup();
          }}
        />
      )}

      {!isModalVisible && showMemberJoinVoltSaveModal && (
        <VoltModal
          showMemberJoinVoltSaveModal={showMemberJoinVoltSaveModal}
          onClose={() => {
            setShowMemberJoinVoltSaveModal(false);
            changeMainPopupList(MainPopupType.MEMBER_JOIN_VOLT_SAVE, false);
            handleMainPopup();
          }}
        />
      )}

      {/*  [6396]web main tutorial 제거 */}
      {/* {!isModalVisible && !showMemberJoinVoltSaveModal && isShowTutorial && (
        <MainTutorial
          show={isShowTutorial}
          onClose={() => {
            setIsShowTutorial(false);
          }}
        />
      )} */}

      {!isModalVisible &&
        !showMemberJoinVoltSaveModal &&
        !isShowTutorial &&
        showBottomSheet &&
        showBottomSheetOnce && (
          <MainPopupBanner
            onCloseAction={() => {
              setShowBottomSheetOnce(false);
              changeMainPopupList(MainPopupType.BOTTOM_BANNER, false);
              handleMainPopup();
            }}
          />
        )}

      {!isModalVisible &&
        !showMemberJoinVoltSaveModal &&
        !isShowTutorial &&
        !showBottomSheet && (
          <MainTextBanner
            onCloseAction={() => {
              changeMainPopupList(MainPopupType.TEXT_NOTICE, false);
            }}
            onModalHide={() => {
              handleMainPopup();
            }}
          />
        )}
      {/*
      {showMainMallNotice && (
        <MainMallNotice
          isVisible={showMainMallNotice}
          onclose={() => {
            setShowMainMallNotice(false);
            changeMainPopupList(MainPopupType.MAIN_MALL_NOTICE, false);
          }}
          onModalHide={() => {
            handleMainPopup();
          }}
        />
      )} */}
    </MainLayout>
  );
});

export default HomeScreen;
