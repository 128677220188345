import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import AuthStore from "../../../store/AuthStore";
// import { navigate } from "../../../navigation/NavigationFunction";
import CommonStore from "../../../store/CommonStore";
import { ReactComponent as BtnArrowRight } from "../../../assets/icons/btn_open_16.svg";
import { ReactComponent as IconMall } from "../../../assets/icons/icon_link_mall.svg";
import { ReactComponent as IconMission } from "../../../assets/icons/icon_link_mission.svg";
import { ReactComponent as IconMagazine } from "../../../assets/icons/icon_link_magazine.svg";
import { ReactComponent as IconRanking } from "../../../assets/icons/icon_link_ranking.svg";
import { ReactComponent as IconAttendence } from "../../../assets/icons/icon_link_attendence.svg";
import { ReactComponent as LgUp } from "../../../assets/icons/icon_link_lg_up.svg";
import { ReactComponent as NewCircle } from "../../../assets/icons/icon_new_circle.svg";

import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
// import RemoteConfigStore from "../../../store/RemoteConfigStore";
import { track } from "../../../hooks/tracker/TrackFunction";
// import { useRemoteConfigValue } from "../../../hooks/UseRemoteConfig";
import "./MainProfile.scss";
import MainStore from "../../../store/MainStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import {
  getStringRemoteConfig,
  getNumberRemoteConfig,
} from "../../../utils/remoteConfig";

export interface MainProfileProps {
  isLoggedIn: any;
  profileRef: any;
}

const MainProfile = observer(({ isLoggedIn, profileRef }: MainProfileProps) => {
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const show_up_product_quick_menu = getStringRemoteConfig(
    "show_up_product_quick_menu"
  );
  const up_product_magazine_feedId = getNumberRemoteConfig(
    "up_product_magazine_feedId"
  );

  const { tempMember, sessionUser } = AuthStore;
  const { t } = useTranslation();

  const attendenceMissionId = Number(
    getStringRemoteConfig("attendence_mission_id")
  );

  const profile = isLoggedIn
    ? `${PUBLIC_BUCKET_URL}${sessionUser?.profileUrl || ""}`
    : tempMember?.profileUrl
    ? `${PUBLIC_BUCKET_URL}${tempMember?.profileUrl || ""}`
    : `${PUBLIC_BUCKET_URL}${"/PROFILE/SYSTEM/sample_1.png"}
      `;

  const handleProfileClick = () => {
    if (isLoggedIn) {
      CommonStore.callActHistory(
        ActHistoryTypeCode.PAGE_CLICK,
        ActHistoryTargetId.MY_HOME,
        ActPageCode.MAIN
      );
      goTo("/myhome", {
        state: {
          targetUserId: sessionUser?.memberUuid,
          nickname: sessionUser?.nickname,
          profileUrl: sessionUser?.profileUrl,
          profileBackgroundColor: sessionUser?.profileBackgroundColor,
        },
      });
    } else {
      MainStore.setShowJoinModal(true);
    }
  };

  return (
    <div id="MainProfile">
      <div
        className="my_wrap"
        ref={profileRef}
        onClick={() => {
          handleProfileClick();
        }}
      >
        <div className="my">
          <img className="profile_img" src={profile} alt={"profile"} />
          <div className="my_text_wrap">
            {isLoggedIn ? (
              <div className="my_text_wrap1">
                <div className="mimi_kim">
                  {sessionUser?.nickname
                    ? sessionUser?.nickname
                    : tempMember?.nickname
                    ? tempMember?.nickname
                    : "GUEST"}
                </div>
                <div className="mimi_kim_support_text">
                  <div className="mimi_kim_inner_text">{"님의 "}</div>
                  <div className="mimi_kim_inner_text">
                    <div className="myhome_text">{"마이홈"}</div>
                  </div>
                  <div className="mimi_kim_inner_text">{" 바로가기"}</div>
                </div>
                <BtnArrowRight className="btn_arrow" />
              </div>
            ) : (
              <div className="my_text_wrap1">
                <div className="mimi_kim">{"로그인"}</div>
                <div className="mimi_kim_support_text">
                  <div className="mimi_kim_inner_text">
                    {"하고 가전 취향을 공유해 보세요."}
                  </div>
                </div>
                <BtnArrowRight className="btn_arrow" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="lk_wrap">
        {show_up_product_quick_menu === "true" ? (
          <div
            className="lk_item"
            style={{ position: "relative" }}
            onClick={() => {
              track("click_main_quick_menu", {
                menu_name: "lg_up",
              });
              CommonStore.callActHistory(
                ActHistoryTypeCode.PAGE_CLICK,
                ActHistoryTargetId.LG_UP,
                ActPageCode.MAIN,
                ActSectionCode.QUICKMENU
              );
              if (up_product_magazine_feedId > 0)
                goTo(`/Magazine/${up_product_magazine_feedId}`, {
                  state: {
                    feedId: up_product_magazine_feedId,
                  },
                });
            }}
          >
            <div className="in_item">
              <LgUp />
              <div className="txt_lk_title">{t("screen.main.label.lgUp")}</div>
            </div>
            <NewCircle style={{ position: "absolute", top: -6, right: 1.75 }} />
          </div>
        ) : (
          <div
            className="lk_item"
            onClick={() => {
              track("click_main_quick_menu", {
                menu_name: "mall",
              });
              CommonStore.callActHistory(
                ActHistoryTypeCode.PAGE_CLICK,
                ActHistoryTargetId.MALL_MAIN,
                ActPageCode.MAIN
              );
              // navigate("MallMainScreen");
              CommonStore.setShowDownloadAppDrive(true);
            }}
          >
            <div className="in_item">
              <IconMall />
              <div className="txt_lk_title">
                {t("screen.main.label.shopping")}
              </div>
            </div>
          </div>
        )}
        <div
          className="lk_item"
          onClick={() => {
            track("click_main_quick_menu", {
              menu_name: "mission",
            });
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.MISSION,
              ActPageCode.MAIN
            );

            // RemoteConfigStore.show_brand_page === "true"
            //   ? navigate("MissionScreen")
            //   : navigate("MainTab", {
            //       screen: "MissionStack",
            //     });
            goTo("/Mission");
          }}
        >
          <div className="in_item">
            <IconMission />
            <div className="txt_lk_title">{t("screen.main.label.mission")}</div>
          </div>
        </div>
        <div
          className="lk_item"
          onClick={() => {
            track("click_main_quick_menu", {
              menu_name: "magazine",
            });
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.MAGAZINE,
              ActPageCode.MAIN
            );
            goTo("/magazine");
          }}
        >
          <div className="in_item">
            <IconMagazine />
            <div className="txt_lk_title">
              {t("screen.main.label.magazine")}
            </div>
          </div>
        </div>
        <div
          className="lk_item"
          onClick={() => {
            track("click_main_quick_menu", {
              menu_name: attendenceMissionId !== 0 ? "attendence" : "ranking",
            });

            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              attendenceMissionId !== 0
                ? ActHistoryTargetId.ATTENDANCE
                : ActHistoryTargetId.RANKING,
              ActPageCode.MAIN
            );

            if (attendenceMissionId !== 0) {
              goTo("/MissionDetail", {
                state: {
                  missionId: attendenceMissionId,
                },
              });
            } else {
              CommonStore.setShowDownloadAppDrive(true);
            }
          }}
        >
          <div className="in_item">
            {attendenceMissionId != 0 ? <IconAttendence /> : <IconRanking />}
            <div className="txt_lk_title">
              {attendenceMissionId != 0
                ? t("screen.main.label.attendence")
                : t("screen.main.label.ranking")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MainProfile;
