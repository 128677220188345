export const flex = {
  display: 'flex',
}

export const flex_1 = {
  flex: 1,
  minWidth: 0,
  minHeight: 0,
}

export const flex_2 = {
  flex: 2,
}

export const flex_3 = {
  flex: 3,
}

export const flex_4 = {
  flex: 4,
}

export const flex_5 = {
  flex: 5,
}

export const vertical = {
  flexDirection: 'column',
}

export const horizon = {
  flexDirection: 'row',
}

export const reverse_horizon = {
  flexDirection: 'row-reverse',
}

export const wrap = {
  flexWrap: 'wrap',
}
