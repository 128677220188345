export enum PlatformTypes {
  ANDROID_APP = 'android_app',
  IOS_APP = 'ios_app',
  ANDROID_WEB = 'android_web',
  IOS_WEB = 'ios_web',
  WINDOWS_WEB = 'windows_web',
  MACOS_WEB = 'macos_web',
  WEB = 'web',
}

export class Platform {
  public static readonly OS = getPlatform()
  public static readonly isApp =
    Platform.OS === (PlatformTypes.ANDROID_APP || PlatformTypes.IOS_APP)
}

// 사용자 에이전트 문자열을 가져오는 함수
export function getUserAgent(): string {
  return navigator.userAgent
}

function getPlatform(): PlatformTypes {
  const userAgent = window.navigator.userAgent
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (macosPlatforms.some((platform) => userAgent.includes(platform))) {
    return PlatformTypes.MACOS_WEB
  } else if (iosPlatforms.some((platform) => userAgent.includes(platform))) {
    if (/ios_app/.test(userAgent)) {
      return PlatformTypes.IOS_APP
    } else {
      return PlatformTypes.IOS_WEB
    }
  } else if (
    windowsPlatforms.some((platform) => userAgent.includes(platform))
  ) {
    return PlatformTypes.WINDOWS_WEB
  } else if (/Android/.test(userAgent)) {
    if (/android_app/.test(userAgent)) {
      return PlatformTypes.ANDROID_APP
    } else {
      return PlatformTypes.ANDROID_WEB
    }
  } else {
    return PlatformTypes.WEB
  }
  // } else if (/Linux/.test(userAgent)) {
  //   os = 'Linux';
  // }
}
