import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
} from "../common/api";
import {
  NicknameValidationResult,
  EmailValidationResult,
  UserInfo,
  UpdateMemberRequest,
  AddMemberRequest,
  MemberInfoResponse,
  CreateMemberPreferenceRequest,
  SocialLoginRequest,
  UpdateMemberPasswordRequestVO,
  WithdrawalRequestVO,
  SocialNotLoginResponseVO,
  UpdateMemberPreferenceRequest,
  MemberState,
  UpdateMemberStateRequest,
  EmailLoginRequest,
  UserInformationUpdate,
  LevelInfo,
  WithdrawalMemberInfoRequest,
  RankingPosterResponse,
  RankingPosterStyle,
  LogCpaRequest,
  SimilarFollowersRequest,
  SimilarFollowersResponse,
} from "./model";
import CommonResponse from "../http";
import { CommentUserListItem } from "../comment/model";
import { SuccessOrNot } from "../../constants/Common.enum";
import { handleLogout } from "../../hooks/tracker/TrackFunction";
// import ReactNativeIdfaAaid, {
//   AdvertisingInfoResponse,
// } from "@sparkfabrik/react-native-idfa-aaid";
import { getTempMemberFromStorage } from "../../service/Preference.service";
import { getSessionUserFromStorage } from "../../service/Session.service";
import { native } from "../../native/native";
import { Platform } from "../../native/platform";
import { encrypt } from "../../utils/encrypt";
import CommonStore from "../../store/CommonStore";
import { ActHistoryTargetId, ActHistoryTypeCode, ActPageCode } from "../../constants/ActHistory";

export const validateNickname = async (
  nickname: string,
  recommenderFlag = false,
  isLoading = true
): Promise<NicknameValidationResult> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/member/validation/nickname",
      body: {
        nickname,
        recommenderFlag,
      },
    },
    isLoading
  );

  return (
    response.successOrNot === "Y"
      ? response.data
      : response.statusCode === "HAS_BAD_WORD"
      ? { hasBadWord: true }
      : null
  ) as NicknameValidationResult;
};

export const getRecommendNickname = async (): Promise<string> => {
  const response = await callAPIWithGETMethod(
    { url: "/v1/member/recommend/nickname" },
    true
  );
  return (response?.successOrNot === "Y" ? response?.data : null) as string;
};

export const validateEmail = async (
  emailAddress: string,
  isLoading = true
): Promise<EmailValidationResult> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/member/validation/email",
      body: {
        emailAddress,
      },
    },
    isLoading
  );
  return (
    response.successOrNot === "Y" ? response.data : null
  ) as EmailValidationResult;
};

export const createMember = async (
  createMemberRequest: AddMemberRequest,
  isLoading = true
): Promise<CommonResponse> => {
  const token = await native.getPushToken();

  return await callAPIWithPOSTMethod(
    {
      url: "/v1/member",
      body: {
        ...createMemberRequest,
        devicePushToken: token,
        phoneNumber: encrypt(createMemberRequest.phoneNumber),
        password: encrypt(createMemberRequest.password),
        webServiceFlag: true,
      },
    },
    isLoading
  );
};

export const memberCpaLog = async (
  request: LogCpaRequest,
  isLoading = true
) => {
  return await callAPIWithPOSTMethod(
    { url: "/v1/member/cpa/log", body: { ...request } },
    isLoading
  );
};

export const updateMemberPromotionAll = async (
  ynValue: string,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPUTMethod(
    {
      url: "/v1/member",
      body: {
        emailAllowYn: ynValue,
        smsAllowYn: ynValue,
        pushAllowYn: ynValue,
      },
    },
    isLoading
  );
};

export const updateMember = async (
  updateMemberRequest: UpdateMemberRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPUTMethod(
    { url: "/v1/member", body: updateMemberRequest },
    isLoading
  );
};

export const emailLogin = async (
  emailAddress: string,
  password: string,
  isLoading = true
): Promise<CommonResponse<UserInfo>> => {
  const token = await native.getPushToken();

  return await callAPIWithPOSTMethod(
    {
      url: "/v1/session",
      body: {
        emailAddress,
        password: encrypt(password),
        devicePushToken: token,
        webServiceFlag: true,
      },
    },
    isLoading
  );
};

export const socialLogin = async (
  socialLoginRequest: SocialLoginRequest,
  isLoading = true
): Promise<CommonResponse<UserInfo & SocialNotLoginResponseVO>> => {
  const token = await native.getPushToken();

  return await callAPIWithPOSTMethod(
    {
      url: "/v1/session/socialLogin",
      body: { ...socialLoginRequest, devicePushToken: token },
    },
    isLoading
  );
};

export const findEmail = async (
  nickname: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: "/v1/member/email/" + nickname,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

export const findNickname = async (
  nickname: string,
  size = 50,
  isLoading = true
): Promise<Array<CommentUserListItem>> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/member/nicknames",
      params: {
        nickname,
        size,
      },
    },
    isLoading
  );
  return (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<CommentUserListItem>;
};

export const createMemberPreference = async (
  request: CreateMemberPreferenceRequest,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithPOSTMethod(
      {
        url: "/v1/member/preference",
        body: request,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

export const updateMemberPreference = async (
  request: UpdateMemberPreferenceRequest,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithPUTMethod(
      {
        url: "/v1/member/preference",
        body: request,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

export const getMemberInfo = async (
  memberUuid: string,
  detailYn = "Y",
  isLoading = true
): Promise<MemberInfoResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/members",
      params: {
        memberUuid,
        detailYn,
      },
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as MemberInfoResponse;
};

export const getMemberInfoResponse = async (
  memberUuid: string,
  detailYn = "Y",
  isLoading = true
): Promise<CommonResponse<any>> => {
  return await callAPIWithGETMethod(
    {
      url: "/v1/members",
      params: {
        memberUuid,
        detailYn,
      },
    },
    isLoading
  );
};

export const findMemberLevel = async (
  memberUuid: string,
  isLoading = true
): Promise<LevelInfo> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/member/level",
      params: {
        memberUuid,
      },
    },
    isLoading
  );
  return (response?.successOrNot === "Y" ? response?.data : null) as LevelInfo;
};

// export const sendNonIdentifyInfo = async (): Promise<CommonResponse> => {
//   const sessionUser = await getSessionUserFromStorage();
//   const tempMember = await getTempMemberFromStorage();
//   const uuid = sessionUser?.memberUuid || tempMember?.tempMemberUUID || "";
//   const osType = Platform.OS;
//   const info = await ReactNativeIdfaAaid.getAdvertisingInfo();
//   const adid = info.id;
//   const limited =
//     adid == null || adid == "00000000-0000-0000-0000-000000000000"
//       ? true
//       : info.isAdTrackingLimited;
//   const body = {
//     uuid: uuid,
//     osType: osType,
//     adidIdfa: adid || "00000000-0000-0000-0000-000000000000",
//     limited: limited ? 1 : 0,
//   };

//   return await callAPIWithPOSTMethod(
//     {
//       url: "/v1/nonIdentify/nonIdentifyInfo",
//       body: body,
//     },
//     true
//   );
// };

export const autoLogin = async (
  idToken: string,
  sessionId: string,
  isLoading = true
): Promise<CommonResponse> => {

  try {
    CommonStore.callActHistory(
      ActHistoryTypeCode.AUTO_LOGIN,
      ActHistoryTargetId.AUTO_LOGIN,
      ActPageCode.AUTO_LOGIN
  )

  } catch (error) {
    console.error("Failed to callActHistory:", error);
  }
    
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  const token = await native.getPushToken();
  response = await callAPIWithPOSTMethod(
    {
      url: "/v1/session/autoLogin",
      body: {
        idToken,
        sessionId,
        devicePushToken: token,
      },
    },
    isLoading,
    false
  );
  return response;
};

export const logout = async (
  idToken: string,
  sessionId: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithPOSTMethod(
    {
      url: "/v1/session/logout",
      body: {
        idToken,
        sessionId,
      },
    },
    isLoading
  );

  handleLogout();

  return response;
};

export const getUserUpdateDataFromDB = async (
  memberUuid: string,
  isLoading = true
) => {
  let response: CommonResponse<UserInformationUpdate> = {
    successOrNot: "N",
    statusCode: "",
  };
  response = await callAPIWithGETMethod(
    {
      url: "/v1/member/accountInfo",
      params: { memberUuid },
    },
    isLoading
  );
  return response;
};

export const changePassword = async (
  request: UpdateMemberPasswordRequestVO,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/member/password",
      body: {
        ...request,
        password: encrypt(request.password),
        webServiceFlag: true,
      },
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
  );
};

export const withdraw = async (
  request: WithdrawalRequestVO,
  isLoading = true
) => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/member/withdrawal",
      body: request,
    },
    isLoading
  );
};

export const withdrawalIsPossible = async (memberUuid: string) => {
  return await callAPIWithGETMethod(
    { url: "/v1/member/withdrawal/ispossible", params: { memberUuid } },
    true
  );
};

export const getMemberState = async (
  emailAddress: string,
  isLoading = true
) => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/member/state",
      params: { emailAddress },
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as MemberState;
};

export const updateMemberStateCode = async (
  updateMemberStateRequest: UpdateMemberStateRequest,
  isLoading = true
) => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/member/state",
      body: updateMemberStateRequest,
    },
    isLoading
  );

  return response?.successOrNot;
};

export const restoreEmailMember = async (
  emailLoginRequest: EmailLoginRequest,
  isLoading = true
) => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/member/restore",
      body: emailLoginRequest,
    },
    isLoading
  );

  return response?.successOrNot;
};

export const restoreSocialMember = async (
  socialLoginRequest: SocialLoginRequest,
  isLoading = true
) => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/memberSocial/restore",
      body: socialLoginRequest,
    },
    isLoading
  );

  return response?.successOrNot;
};

export const deleteEmailMember = async (
  emailLoginRequest: EmailLoginRequest,
  isLoading = true
) => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/member",
      body: emailLoginRequest,
    },
    isLoading
  );

  return response?.successOrNot;
};

export const deleteSocialMember = async (
  socialLoginRequest: SocialLoginRequest,
  isLoading = true
) => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/memberSocial",
      body: socialLoginRequest,
    },
    isLoading
  );

  return response?.successOrNot;
};

export const passwordValidate = async (
  emailAddress: string,
  password: string,
  isLoading = true
): Promise<CommonResponse<UserInfo>> => {
  const token = await native.getPushToken();
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/session/passwordValidate",
      body: {
        emailAddress,
        password: password,
        devicePushToken: token,
      },
    },
    isLoading
  );
};

export const withdrawalCondition = async (
  memberUuid: string,
  isLoading = true
) => {
  return await callAPIWithGETMethod(
    {
      url: "/v1/member/withdrawalCondition",
      params: {
        memberUuid,
      },
    },
    isLoading
  );
};

export const withdrawalMemberInfo = async (
  withdrawalMemberInfoRequest: WithdrawalMemberInfoRequest,
  isLoading = true
) => {
  return await callAPIWithPUTMethod(
    {
      url: "/v1/member/withdrawalMemberInfo",
      body: withdrawalMemberInfoRequest,
    },
    isLoading
  );
};

export const getRankingPosters = async (
  isLoading = true
): Promise<Array<RankingPosterResponse>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithGETMethod(
    { url: "/v1/member/ranking/posters" },
    isLoading
  );
  return (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<RankingPosterResponse>;
};

export const saveUserStylePoster = async (
  request: RankingPosterStyle,
  isLoading = true
) => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/member/ranking/posters/style",
      body: request,
    },
    isLoading
  );
};

export const getEcommerceGenerateKey = async (): Promise<string> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithGETMethod(
    { url: "/v1/member/ecommerce/generateKey" },
    false
  );
  return (response.successOrNot === "Y" ? response.data : null) as string;
};

export const ecommerceSignup = async () => {
  return await callAPIWithGETMethod(
    { url: "/v1/member/ecommerce/signup" },
    false
  );
};

export const getSimilarFollowers = async (
  request: SimilarFollowersRequest,
  isLoading = false
): Promise<CommonResponse<SimilarFollowersResponse>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithGETMethod(
    { url: "/v1/member/similarFollowers", params: request },
    isLoading
  );
  return (
    response.successOrNot === "Y" ? response : null
  ) as CommonResponse<SimilarFollowersResponse>;
};

export const updateLoginTime = async (
  isLoading = true,
  showToast = false
): Promise<CommonResponse<UserInfo & SocialNotLoginResponseVO>> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/session/login/time",
    },
    isLoading
  );
};
