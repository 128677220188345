//원본 소스
// import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import { observer } from "mobx-react";
// import { FlatList, Platform, StyleSheet, Text, View } from "react-native";
// import { Arranges, Assets, Colors, FlexBoxs, Icons, Positions, Spacings, Texts } from "../../../assets/styles";
// import { responsiveScreenHeight, responsiveScreenWidth, responsiveWidth } from "react-native-responsive-dimensions";
// import { navigate } from "../../../navigation/NavigationFunction";
// import { ParamListBase, useNavigation, useScrollToTop } from "@react-navigation/native";
// import { DrawerNavigationProp } from "@react-navigation/drawer";
// import MissionStore from "../../../store/MissionStore";
// import { useTranslation } from "react-i18next";
// import { MISSION_RESULT_COUNT, MISSION_TYPE_PATH } from "../../../constants/mission";
// import { Styles } from "../../../../types/Style";
// import MissionMainList from "./MissionMainList";
// import AuthStore from "../../../store/AuthStore";
// import FlatListWithStickyTabs from "../../../components/common/FlatListWithStickyTabs";
// import Toast from "react-native-toast-message";
// import ToastConfig from "../../../components/common/ToastConfig";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import TabStore from "../../../store/TabStore";
// import { StackTypeCode } from "../../../constants/Stack";
import "./MissionMain.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";
import {
  Arranges,
  Assets,
  Colors,
  FlexBoxs,
  Icons,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import MissionStore from "../../../store/MissionStore";
import { useTranslation } from "react-i18next";
import {
  MISSION_RESULT_COUNT,
  MISSION_TYPE_PATH,
} from "../../../constants/mission";
import MissionMainList from "./MissionMainList";
import AuthStore from "../../../store/AuthStore";
import TabStore from "../../../store/TabStore";
import MissionItem from "./MissionItem";

import { Styles } from "../../../assets/types/Style";
import { Virtuoso } from "react-virtuoso";

import ListWithStickyTabs from "../../../components/common/ListWithStickyTabs";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useWindowScroll } from "react-use";
import { ActHistoryRequest } from "../../../api/data/model";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import CommonStore from "../../../store/CommonStore";
import { sendActHistory } from "../../../api/data/api";

export interface FlatListWithStickyTabsProps {
  scrollableHeader?: React.ReactElement;
  stickyItems?: React.ReactElement;
  listFooterComponent?: React.ReactElement;
  stickyTabs?: Tab[];
  stickyTabsCustom?: React.ReactElement;
  onChangeTab?: (key: string) => void;
  activeTabKey?: string;
  tabStyles?: {
    tabTextContainerStyle?: React.CSSProperties;
    tabTextContainerActiveStyle?: React.CSSProperties;
    tabTextStyle?: React.CSSProperties;
    tabTextActiveStyle?: React.CSSProperties;
    tabWrapperStyle?: React.CSSProperties;
    tabsContainerStyle?: React.CSSProperties;
  };
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  showScrollToTop?: boolean;
  topButtonRight?: number;
  contentScrollRef: React.RefObject<HTMLDivElement>;
  onRefresh?: () => void;
}

export interface Tab {
  key: string;
  title?: string;
  accessibilityLabel?: string;
  content?: React.ReactElement | ((isActive: boolean) => React.ReactElement);
}

const MissionMain = observer(
  ({ location }: { location: MISSION_TYPE_PATH }) => {
    const [pageIndex, setPageIndex] = useState(0);
    // const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
    const { t } = useTranslation();
    const {
      missionList,
      selectedTab,
      // loadMore,
      // setMissionList,
      getMissionList,
      getMoreMissionList,
      setSelectedTab,
      setLoading,
      setLoadMore,
      clearMissionList,
      loading,
    } = MissionStore;

    const { sessionUser } = AuthStore;

    const { y } = useWindowScroll();

    useEffect(() => {
      setTimeout(() => {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
          actPageCode: ActPageCode.MISSION,
          actSourcePageCode: CommonStore.fixedPreviousRouteName,
          attr1: (y === 0).toString(),
        };
        void sendActHistory(actHistory);
      }, 1000);
    }, []);

    useEffect(() => {
      clearMissionList();
      void getMissionList(location ?? selectedTab, MISSION_RESULT_COUNT, 0);

      // clearMissionList();
      // void getMissionList(
      //   location ?? MISSION_TYPE_PATH.ONGOING,
      //   MISSION_RESULT_COUNT,
      //   0
      // );
    }, [getMissionList, clearMissionList, location]);

    // const stickyTabsRef = useRef<FlatList>(null);
    // useScrollToTop(stickyTabsRef);
    const stickyTabsRef = useRef<HTMLDivElement>(null);

    const handleAlarm = () => {
      goTo("NotificationScreen");
    };

    const handleSearch = () => {
      goTo("SearchScreen");
    };

    const showDrawerMenu = () => {
      // navigation?.openDrawer();
    };

    const handleMoreRequest = () => {
      void getMoreMissionList(selectedTab, MISSION_RESULT_COUNT, pageIndex + 1);
      setPageIndex(pageIndex + 1);
    };


    const initMissionList = useCallback(
      (tab: MISSION_TYPE_PATH) => {
        setLoading(true);
        setLoadMore(true);
        setPageIndex(0);

        void getMissionList(tab, MISSION_RESULT_COUNT, 0);
      },
      [setLoading, setLoadMore, getMissionList]
    );

    const renderForeground = () => {
      return (
        <div className="mission" style={styles.mission}>
          <div className="mission_header" style={styles.mission_header}>
            <p
              className="mission_header_text1"
              style={styles.mission_header_text1}
            >
              {t("screen.mission.title")}
            </p>
            <p
              className="mission_header_text2"
              style={styles.mission_header_text2}
            >
              {t("screen.mission.message.subTitle")}
            </p>
          </div>
        </div>
      );
    };

    const moveOnGoing = (tabKey: MISSION_TYPE_PATH) => {
      setSelectedTab(tabKey);
    };

    const renderTabs = () => {
      if (sessionUser?.memberUuid === undefined) {
        return [
          {
            key: MISSION_TYPE_PATH.ONGOING,
            title: t(`screen.mission.button.ongoing`),
            content: <MissionMainList missionList={missionList} onMore={handleMoreRequest} />,
          },
          {
            key: MISSION_TYPE_PATH.END,
            title: t(`screen.mission.button.end`),
            content: <MissionMainList missionList={missionList} onMore={handleMoreRequest} />,
          },
        ];
      } else {
        return [
          {
            key: MISSION_TYPE_PATH.ONGOING,
            title: t(`screen.mission.button.ongoing`),
            content: <MissionMainList missionList={missionList} onMore={handleMoreRequest} />,
          },
          {
            key: MISSION_TYPE_PATH.END,
            title: t(`screen.mission.button.end`),
            content: <MissionMainList missionList={missionList} onMore={handleMoreRequest} />,
          },
          {
            key: MISSION_TYPE_PATH.PARTICIPATE,
            title: t(`screen.mission.button.participate`),
            content: <MissionMainList missionList={missionList} onMore={handleMoreRequest} />,
          },
        ];
      }
    };

    // const onRefresh = () => {
    //   initMissionList(selectedTab);
    // };

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    //탭 컴포넌트
    const Tab = ({
      activeTabIndex,
      stickyTabs,
      onChangeTab,
      tabStyles,
    }: {
      activeTabIndex: number;
    } & Pick<
      FlatListWithStickyTabsProps,
      "stickyTabs" | "onChangeTab" | "tabStyles"
    >) => {
      const handleTabPress = useCallback(
        (key: string) => () => {
          onChangeTab && onChangeTab(key);
        },
        [onChangeTab]
      );

      return (
        <div style={{ flexDirection: "row", ...tabStyles?.tabsContainerStyle }}>
          {stickyTabs?.map((tab, index) => (
            <span
              key={tab.key}
              style={
                activeTabIndex === index
                  ? { ...tabStyles?.tabTextContainerActiveStyle }
                  : { ...tabStyles?.tabTextContainerStyle }
              }
              onClick={handleTabPress(tab.key)}
            >
              <span
                style={
                  activeTabIndex === index
                    ? { ...tabStyles?.tabTextActiveStyle }
                    : { ...tabStyles?.tabTextStyle }
                }
              >
                {tab.title}
              </span>
            </span>
          ))}
        </div>
      );
    };

    //빈화면 컴포넌트
    const renderEmpty = () => {
      return (
        <div
          className="empty-icon"
          style={{
            backgroundImage: "url('../../../assets/images/nodata_mission.png')",
          }}
        >
          <div style={styles.empty}>
            <div style={styles.empty_text_wrap}>
              {selectedTab == MISSION_TYPE_PATH.ONGOING ? (
                <p>{t("screen.mission.message.noOngoing")}</p>
              ) : selectedTab == "end" ? (
                <p>{t("screen.mission.message.noEnd")}</p>
              ) : (
                <>
                  <p style={styles.empty_text}>
                    {t("screen.mission.message.noParticipate1")}
                  </p>
                  <p style={styles.empty_text}>
                    {t("screen.mission.message.noParticipate2")}
                  </p>
                  <p style={styles.empty_text}>
                    {t("screen.mission.message.noParticipate3")}
                  </p>
                </>
              )}
            </div>
            {selectedTab !== MISSION_TYPE_PATH.ONGOING && (
              <button
                style={styles.event_view_btn}
                onClick={() => {
                  setSelectedTab(MISSION_TYPE_PATH.ONGOING);
                  void getMissionList(
                    MISSION_TYPE_PATH.ONGOING,
                    MISSION_RESULT_COUNT,
                    0
                  );
                }}
              >
                <p style={styles.event_view_btn_text}>
                  {t("screen.mission.button.showOngoing")}
                </p>
              </button>
            )}
          </div>
        </div>
      );
    };

    //탭 스타일
    const tabStyles = {
      tabTextActiveStyle: styles.tabTextActiveStyle,
      tabTextContainerActiveStyle: styles.tabTextContainerActiveStyle,
      tabTextContainerStyle: styles.tabTextContainerStyle,
      tabTextStyle: styles.tabTextStyle,
      tabWrapperStyle: styles.tabWrapperStyle,
      tabsContainerStyle: styles.tabsContainerStyle,
    };

    return (
      <div id="MissionMain">
        {/* {renderHeader()} */}

        <ListWithStickyTabs
          // className="mission-list"
          className="mission-sticky-tabs tab-container"
          contentScrollRef={stickyTabsRef}
          // onRefresh={onRefresh}
          scrollableHeader={renderForeground()}
          stickyTabs={renderTabs()}
          // tabStyles={{
          //   tabTextActiveStyle: styles.tabTextActiveStyle,
          //   tabTextContainerActiveStyle: styles.tabTextContainerActiveStyle,
          //   tabTextContainerStyle: styles.tabTextContainerStyle,
          //   tabTextStyle: styles.tabTextStyle,
          //   tabWrapperStyle: styles.tabWrapperStyle,
          //   tabsContainerStyle: styles.tabsContainerStyle,
          // }}
          // endReached={() => {
          //   if (loadMore) {
          //     setLoadMore(false);
          //     void handleMoreRequest();
          //   }
          // }}
          activeTabKey={selectedTab}
          onChangeTab={(key: string) => {
            setSelectedTab(key as MISSION_TYPE_PATH);
            initMissionList(key as MISSION_TYPE_PATH);
          }}
          showScrollToTop={true}
        />

        {/* <FlatListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        onRefresh={onRefresh}
        scrollableHeader={renderForeground()}
        stickyTabs={renderTabs()}
        tabStyles={{
          tabTextActiveStyle: styles.tabTextActiveStyle,
          tabTextContainerActiveStyle: styles.tabTextContainerActiveStyle,
          tabTextContainerStyle: styles.tabTextContainerStyle,
          tabTextStyle: styles.tabTextStyle,
          tabWrapperStyle: styles.tabWrapperStyle,
          tabsContainerStyle: styles.tabsContainerStyle,
        }}
        // onEndReached={() => {
        //   if (loadMore) {
        //     setLoadMore(false);
        //     void handleMoreRequest();
        //   }
        // }}
        activeTabKey={selectedTab}
        onChangeTab={(key: string) => {
          setSelectedTab(key as MISSION_TYPE_PATH);
          initMissionList(key as MISSION_TYPE_PATH);
        } }
        showScrollToTop={true} 
        />
    */}
      </div>
    );
  }
);

export default MissionMain;

export const styles: Styles = {
  tab_list: {
    ...Spacings.margin_top_32,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  tab_btn: {
    ...Spacings.margin_right_18,
  },
  tab_btn_text: {
    ...Texts.btn_text_4,
    ...Colors.font_aaa,
  },
  tab_btn_active_text: {
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },

  mission_list: {
    ...Spacings.padding_left_right_16_responsive,
  },
  mission_item: {
    ...Spacings.margin_bottom_35,
  },
  mission_image: {
    // width: responsiveScreenWidth(91.7),
    // height: responsiveScreenWidth(91.7) * 0.614,
  },
  inner: {
    // ...Positions.fullscreen,
    ...Spacings.border_radius_6,
    backgroundColor: "rgba(34,34,34,0.6)",
  },
  mission_info_wrap: {
    ...Spacings.margin_top_12,
    ...Spacings.padding_right_left_4,
  },
  mission_title: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  mission_info: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.margin_top_5,
  },
  mission_volt_info: {
    ...Spacings.margin_top_5,
  },
  mission_volt_text: {
    ...Spacings.margin_top_5,
    ...Texts.font_size_12,
    ...Texts.font_weight_400,
    ...Texts.font_letter_spacing_048,
    ...Colors.font_222,
  },
  date_wrap: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  date_label: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_bold,
  },
  date: {
    ...Texts.contents_text_5,
    ...Spacings.margin_left_4,
  },
  d_day: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_bold,
    ...Colors.font_a760b1,
    ...Spacings.margin_left_6,
  },
  mission_type: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  mission_type_text: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
    ...Spacings.margin_left_2,
  },
  winner_btn: {
    height: 53,
    borderWidth: 1,
    ...Colors.border_color_000,
    ...Spacings.border_radius_4,
    ...Spacings.margin_top_12,
    ...Arranges.center_v_h,
  },
  winner_btn_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_500,
  },
  empty_icon: {
    // width: responsiveScreenWidth(91.7),
    // height: responsiveScreenHeight(43),
  },
  empty: {
    // ...Positions.fullscreen,
    ...Arranges.center_v_h,
  },
  empty_text_wrap: {
    ...Arranges.between,
    ...Arranges.center_h,
    height: 69,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  event_view_btn: {
    ...Spacings.margin_top_14,
    ...Spacings.border_radius_300,
    ...Colors.border_color_a760b1,
    ...Arranges.center_v_h,
    width: 145,
    height: 44,
    borderWidth: 1,
  },
  event_view_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },
  modal: {
    // width: responsiveWidth(100),
    // ...Positions.fullscreen,
    ...Colors.background_fff,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    // ...Assets.modal_box_shadow,
    ...Spacings.padding_top_10,
  },
  modal_header: {
    height: 48,
    ...Arranges.center_v_h,
    // ...Positions.relative,
  },
  modal_body: {
    ...Spacings.padding_left_right_16_responsive,
    ...Spacings.padding_top_20,
    ...Spacings.padding_bottom_30,
  },
  icons: {
    // ...Icons.icon_32,
    // ...Positions.absolute,
    // ...Positions.top_6,
    // ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  title_wrap: {
    ...Spacings.padding_bottom_24,
  },
  title_text: {
    ...Texts.contents_text_1,
    ...Texts.font_weight_500,
  },
  contents_wrap: {
    ...Spacings.margin_top_22,
  },
  contents_text: {
    ...Texts.contents_text_28,
    lineHeight: 26,
  },
  modal_winner_btn: {
    ...Colors.border_color_eee,
    ...Spacings.border_radius_4,
    ...Spacings.margin_top_20,
    ...Arranges.center_v_h,
    height: 53,
    borderWidth: 1,
  },
  modal_winner_btn_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_500,
  },
  alert_modal: {
    // width: responsiveWidth(82),
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    // ...Assets.modal_box_shadow,
  },
  alert_modal_header: {
    ...Arranges.center_v_h,
    // ...Positions.relative,
    height: 64,
  },
  alert_header_text: {
    ...Texts.modal_text_0,
  },
  alert_modal_body: {
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    ...Spacings.padding_bottom_24,
  },
  alert_contents_text: {
    ...Texts.contents_text_31,
    ...Colors.font_222,
    lineHeight: 24,
  },
  alert_modal_footer: {
    ...FlexBoxs.horizon,
    height: 48,
  },
  button: {
    ...Colors.background_222,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
    ...Spacings.border_bottom_left_right_radius_12,
  },
  footer_button_text: {
    ...Texts.button_text_3,
    ...Colors.font_fff,
  },

  table: {
    ...Spacings.margin_top_30,
  },
  table_header: {
    ...FlexBoxs.horizon,
    ...Colors.background_999999,
    ...Spacings.border_top_left_right_radius_6,
  },
  table_header_item: {
    ...Spacings.padding_top_bottom_5,
    ...Arranges.center_v_h,
  },
  table_right_border: {
    borderRightWidth: 1,
    ...Colors.border_color_eee,
  },
  table_header_item_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  table_body: {},
  row: {
    ...FlexBoxs.horizon,
    ...Colors.background_fff,
  },
  last_row: {
    ...Spacings.border_bottom_left_right_radius_6,
  },
  table_body_item: {
    ...Arranges.center_v_h,
    ...Spacings.padding_top_bottom_13,
  },
  table_body_item_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  winner_wrap: {
    ...Arranges.center_v_h,
    ...Colors.background_fcfafc,
    ...Spacings.border_radius_6,
    ...Spacings.padding_top_40,
    ...Spacings.padding_bottom_40,
    ...Spacings.padding_left_right_20,
    // width: responsiveScreenWidth(89.7),
  },
  winner_text: {
    ...Texts.contents_text_18,
    ...Texts.font_weight_bold,
  },
  fail_wrap: {
    height: 111,
    ...Arranges.center_v_h,
  },
  fail_text: {
    ...Spacings.margin_top_4,
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  other_mission_btn: {
    ...Arranges.center_v_h,
    ...Spacings.margin_top_24,
    ...Spacings.border_radius_300,
    ...Colors.border_color_a760b1,
    borderWidth: 1,
    width: 142,
    height: 44,
  },
  other_mission_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },
  loader: {
    // marginVertical: responsiveWidth(44.9),
  },
  more_loader: {
    ...Spacings.padding_bottom_50,
  },
  footer_btn_active: {
    ...Colors.background_222,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  modal_wrap: {
    flex: 1,
  },
  modify_wrap: {
    ...Spacings.margin_top_24,
    ...Arranges.center_v_h,
  },
  modify_info_text: {
    ...Texts.contents_text_9,
  },
  other_wrap: {
    borderTopWidth: 1,
    // width: responsiveWidth(79.4),
    ...Arranges.center_v_h,
    ...Spacings.margin_top_40,
    ...Spacings.padding_top_40,
    ...Colors.border_color_eee,
  },
  modify_btn: {
    width: 96,
    height: 35,
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Spacings.margin_top_14,
    ...Colors.background_fff,
  },
  modify_btn_text: {
    ...Texts.btn_text_3,
    ...Colors.font_666666,
  },
  tabTextContainerStyle: {
    ...Colors.background_transparent,
  },
  tabTextStyle: {
    ...Texts.contents_text_2,
    opacity: 0.4,
    ...Spacings.padding_right_16,
    ...Spacings.padding_top_bottom_12,
    // lineHeight: 20,
  },
  tabTextActiveStyle: {
    ...Texts.contents_text_2,
    opacity: 1,
    // lineHeight: 20,
    ...Spacings.padding_right_16,
    ...Spacings.padding_top_bottom_12,
  },
  tabWrapperStyle: {
    ...Spacings.padding_top_bottom_16,
  },
  tabsContainerStyle: {
    flexDirection: "row",
    ...Spacings.padding_left_right_16_responsive,
  },
  tabTextContainerActiveStyle: {},
};
