import { action, computed, makeObservable, observable } from "mobx";
// // import { BackHandler, NativeEventSubscription } from "react-native";
import { getBadges } from "../api/badge/api";
// import { BadgeItem, BadgeLevelInfo, BadgeRequest } from "../api/badge/model";
import { obtainBadge } from "../api/badge/api";
import { BadgeCode, SearchType, IsMember } from "../constants/Badge.enum";
import { BadgeItem, BadgeLevelInfo, BadgeRequest } from "../api/badge/model";
import AuthStore from "./AuthStore";
import { LevelInfo } from "../api/member/model";
import { findMemberLevel } from "../api/member/api";
// import { BadgeBottomSheetProps } from "../components/common/BadgeBottomSheet";
// import AuthStore from "./AuthStore";
// import { LevelInfo, MemberInfoResponse } from "../api/member/model";
// import { findMemberLevel, getMemberInfo } from "../api/member/api";
// import { cache } from "../utils/cache";

// const VOID_FUNC = () => {
//   //void
// };
class BadgeStore {
  constructor() {
    makeObservable(this);
  }
  @observable _badgeList = "[]";

  @observable _badgeLevelInfo: BadgeLevelInfo = {};

  @observable _tempBottomSheetBadgeList: BadgeItem[] = [];

  // @observable _badgeBottomSheetProps: BadgeBottomSheetProps = {
  //   open: false,
  //   badgeItem: {} as BadgeItem,
  //   onClose: VOID_FUNC,
  // }

  // @observable _backEventSubscription: NativeEventSubscription | null = null

  @observable _openFollowBottomSheet = false;

  @computed get badgeList() {
    return this._badgeList ? JSON.parse(this._badgeList) : [];
  }

  @computed get badgeLevelInfo() {
    return this._badgeLevelInfo;
  }

  //   @computed get badgeBottomSheetProps() {
  //     return this._badgeBottomSheetProps;
  //   }

  //   @computed get tempBottomSheetBadgeList() {
  //     return this._tempBottomSheetBadgeList;
  //   }

  //   @computed get openFollowBottomSheet() {
  //     return this._openFollowBottomSheet;
  //   }

  @action setBadgeList = (list: BadgeItem[]) => {
    this._badgeList = JSON.stringify(list);
  };

  @action setBadgeLevelInfo = (info: any) => {
    this._badgeLevelInfo = info;
  };

  @action getBadgeLevelInfo = async () => {
    const id = AuthStore.sessionUser?.memberUuid;
    if (id) {
      const response: LevelInfo = await findMemberLevel(id);
      this.setBadgeLevelInfo({
        level: response.levelId,
        levelCodeName: response.levelCodeName,
        levelIconUrl: response.levelIconUrl,
      });
    }
  };

  @action getMemberBadges = async (badgeRequest: BadgeRequest) => {
    const response = await getBadges(badgeRequest);
    this.setBadgeList(response ?? []);
    void this.getBadgeLevelInfo();
  };

  //   @action setBadgeBottomSheetProps = (props: BadgeBottomSheetProps) => {
  //     this._badgeBottomSheetProps = props;
  //   };

  //   @action setTempBottomSheetBadgeList = (props: BadgeItem[]) => {
  //     this._tempBottomSheetBadgeList = props;
  //   };

  @action setBackEventSubscription = (action: () => void) => {
    this.clearBackEventSubsription();
    // this._backEventSubscription = BackHandler.addEventListener(
    //   'hardwareBackPress',
    //   () => {
    //     action()
    //     this.clearBackEventSubsription()
    //     return true
    //   }
    // )
  };

  @action closeBadgeBottomSheetProps = () => {
    // this._badgeBottomSheetProps = {
    //   open: false,
    //   badgeItem: {} as BadgeItem,
    //   onClose: VOID_FUNC,
    // }
    // if (this.tempBottomSheetBadgeList.length > 0) {
    //   this.openBadgeBottomSheet(
    //     this.tempBottomSheetBadgeList.pop() as BadgeItem
    //   )
    // }
  };

  @action isContainBadge = (badgeCode: BadgeCode) => {
    const objectBadgeList = JSON.parse(this._badgeList);

    for (let i = 0; i < objectBadgeList.length; i++) {
      if (objectBadgeList[i].badgeCode === badgeCode) {
        return true;
      }
    }

    return false;
  };

  @action obtainBadge = async (badgeCode: BadgeCode) => {
    if (!this.isContainBadge(badgeCode) && AuthStore.sessionUser) {
      const response = await obtainBadge(badgeCode);
      if (response != null && response) {
        this.openBadgeBottomSheet(response);
        void this.getMemberBadges({
          searchType: SearchType.ALL,
          isMember: IsMember.Y,
        });
      }
    }
  };

  @action openBadgeBottomSheet = (badgeItem: BadgeItem) => {
    this.setBackEventSubscription(() => this.closeBadgeBottomSheetProps());
    // if (this._badgeBottomSheetProps.open) {
    //   this._tempBottomSheetBadgeList.push(badgeItem)
    // } else {
    //   this.setBadgeBottomSheetProps({
    //     open: true,
    //     badgeItem: badgeItem,
    //     onClose: () => {
    //       this.closeBadgeBottomSheetProps()
    //     },
    //   })
    // }
  };

  @action setFollowBadgeBottomSheet = (flag: boolean) => {
    this._openFollowBottomSheet = flag;
  };

  //   @action initFollowBadgeCache = () => {
  //     void cache.get("showFollowBadgeBottomSheetStatus").then((result) => {
  //       if (result && result == "complete_signup") {
  //         this.setFollowBadgeBottomSheet(true);
  //       } else {
  //         void cache.set("showFollowBadgeBottomSheetStatus", "").then(() => {
  //           this.setFollowBadgeBottomSheet(false);
  //         });
  //       }
  //     });
  //   };

  @action setFollowBadgeCache = (value: string) => {
    // void cache.set("showFollowBadgeBottomSheetStatus", value);
    this.setFollowBadgeBottomSheet(false);
  };

  @action clearBackEventSubsription = () => {
    // this._backEventSubscription?.remove()
    // this._backEventSubscription = null
  };

  @action clear = () => {
    this._badgeList = "[]";
    this._badgeLevelInfo = {};
    this._tempBottomSheetBadgeList = [];
    this.closeBadgeBottomSheetProps();
  };
}
export default new BadgeStore();
