import { action, computed, makeObservable, observable } from 'mobx'
import { autoLogin, updateLoginTime } from '../api/member/api'
import { UserInfo } from '../api/member/model'
import { SelectedPreference } from '../api/preference/model'

import { TempMember } from '../api/tempMember/model'
import { MemberStateCode, StatusCode } from '../constants/Common.enum'
import { getTempMemberFromStorage } from '../service/Preference.service'
import {
  getSessionUserFromStorage,
  removeSessionUserFromStorage,
  setSessionUserInStorage,
} from '../service/Session.service'
// import crashlytics from "@react-native-firebase/crashlytics";
import { track } from '../hooks/tracker/TrackFunction'

export interface SessionUser {
  nickname?: string
  roleTypeCode?: string
  profileUrl?: string
  profileBackgroundColor?: string
  memberUuid?: string
  preference?: SelectedPreference[]
  emailAddress?: string
  memberStateCode?: MemberStateCode
  loginType?: string
  birthYear?: string
  genderCode?: string
  createdDateTime?: string
  ecommerceMemberNo?: string
  ecommerceUpdateYn?: string
  ecommerceUpdateDateTime?: string
  isLongTermUnused: boolean
  idToken?: string
  sessionId?: string
  homeAppliance?: string[]
  houseMate?: string[]
  roomScale?: string[]
  passwordWrongCount?: number
  recommendedNicknameYn?: string
}
class AuthStore {
  @observable _sessionUser: SessionUser | null = null
  @observable _tempMember: TempMember | null = null
  @observable _showProfileBanner = false
  @observable _showProfileBannerBrandPage = false

  constructor() {
    makeObservable(this)
  }

  @action tryAutoLoginByLocalSession = async (sessionUser: UserInfo) => {
    if (!(sessionUser?.sessionId && sessionUser.idToken)) {
      return false
    }
    const result = await autoLogin(sessionUser?.idToken, sessionUser?.sessionId)

    if (result.data == null) {
      await removeSessionUserFromStorage()
      this.setSessionUser(null)
      return true
    }

    if (result.successOrNot === 'N') {
      if (
        result.statusCode === StatusCode.INCORRECT_USERNAME_OR_PASSWORD ||
        result.statusCode === StatusCode.WITHDRAWAL ||
        result.statusCode === StatusCode.LONG_TERM_UNUSED
      ) {
        await removeSessionUserFromStorage()
        this.setSessionUser(null)
        return true
      }
      return false
    }

    const UserInfo: UserInfo = {
      ...result.data,
      emailAddress: sessionUser?.emailAddress,
      loginType: sessionUser?.loginType,
    }

    await setSessionUserInStorage(UserInfo)
    await this.getSessionUserFromStorageToStore()

    if (!UserInfo.nickname) {
      // reset("PreferenceScreen");
    } else if (!UserInfo.profileUrl) {
      // reset("ProfileSetting", { hideBack: true });
    }

    track('complete_auto_login')
    return true
  }

  @action tryAutoLogin = async (isIntroSplash = false): Promise<boolean> => {
    const sessionUser = (await getSessionUserFromStorage()) as UserInfo

    if (!(sessionUser?.sessionId && sessionUser.idToken)) {
      return false
    }
    const autoLoginResult = await autoLogin(
      sessionUser?.idToken,
      sessionUser?.sessionId
    )

    if (autoLoginResult.successOrNot === 'N') {
      return false
    }

    const UserInfo: UserInfo = {
      ...autoLoginResult.data,
      emailAddress: sessionUser?.emailAddress,
      loginType: sessionUser?.loginType,
    }

    await setSessionUserInStorage(UserInfo)
    await this.getSessionUserFromStorageToStore()

    if (!UserInfo.nickname) {
      // if (!isIntroSplash) reset("PreferenceScreen");
      // else undefined;
    } else if (!UserInfo.profileUrl) {
      // if (!isIntroSplash) reset("ProfileSetting", { hideBack: true });
      // else undefined;
    }

    return true
  }

  @computed get sessionUser() {
    return this._sessionUser
  }

  @computed get tempMember() {
    return this._tempMember
  }

  @computed get showProfileBanner() {
    return this._showProfileBanner
  }

  @computed get showProfileBannerBrandPage() {
    return this._showProfileBannerBrandPage
  }

  @computed get loginType() {
    return this._sessionUser?.loginType ? this._sessionUser?.loginType : ''
  }

  @computed get canEnterMainNav() {
    return !!(
      (this._sessionUser?.nickname &&
        this._sessionUser?.profileUrl &&
        !this._sessionUser.isLongTermUnused) ||
      (this._tempMember?.nickname &&
        (this._tempMember?.profileUrl ||
          this._tempMember?.profileImageFromGallary))
    )
  }

  @computed get getMemberStateCode() {
    return this._sessionUser?.memberStateCode
  }

  @action setSessionUser = (sessionUser: SessionUser | null) => {
    this._sessionUser = sessionUser
    // void crashlytics().setUserId(sessionUser?.memberUuid ? sessionUser.memberUuid : "");
  }

  @action setTempMember = (tempMember: TempMember | null) => {
    this._tempMember = tempMember
  }

  @action getSessionUserFromStorageToStore = async () => {
    const sessionUser = await getSessionUserFromStorage()
    if (sessionUser && sessionUser?.memberUuid) {
      this.setSessionUser({
        ...sessionUser,
        isLongTermUnused: sessionUser.isLongTermUnused || false,
        loginType: sessionUser?.loginType || '',
      })
    } else {
      this.setSessionUser(null)
    }
  }

  @action getTempMemberFromStorageToStore = async () => {
    const tempMember = await getTempMemberFromStorage()
    this.setTempMember(tempMember)
  }

  @action loadStorageToStore = async () => {
    await this.getSessionUserFromStorageToStore()
    await this.getTempMemberFromStorageToStore()
  }

  @action setShowProfileBanner = (val: boolean) => {
    this._showProfileBanner = val
  }

  @action setShowProfileBannerBrandPage = (val: boolean) => {
    this._showProfileBannerBrandPage = val
  }

  @action updateLoginTime = async () => {
    await updateLoginTime()
  }

  @action clear = () => {
    this._sessionUser = null
    this._showProfileBanner = false
    this._showProfileBannerBrandPage = false
  }
}

export default new AuthStore()
