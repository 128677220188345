export enum AllowTypeCode {
  Y = "Y",
  N = "N",
}

export enum RecommendedNicknameCode {
  RECOMMENDED = "RECOMMENDED",
  CHANGED = "CHANGED",
  NA = "NA",
}

export enum GenderTypeCode {
  ALL = "ALL",
  MALE = "MALE",
  FEMALE = "FEMALE",
  NA = "NA",
}

export enum AgeGroupCode {
  ALL = "ALL",
  TWENTIES = "TWENTIES",
  THIRTIES = "THIRTIES",
  FORTIES = "FORTIES",
  FIFTIES = "FIFTIES",
}

export enum CategoryTypeCode {
  ALL = "ALL",
  SOUND = "SOUND",
  SEASON = "SEASON",
  COOK = "COOK",
  VIDEO = "VIDEO",
  HOUSEWORK = "HOUSEWORK",
  BEAUTY = "BEAUTY",
  HYGIENE = "HYGIENE",
  CLOTHES = "CLOTHES",
  HEALTH = "HEALTH",
  DIGITAL = "DIGITAL",
}

export enum RoleTypeCode {
  ADMIN = "ADMIN",
  BASIC = "BASIC",
  CORPORATE = "CORPORATE",
  MASTER_ADMIN = "MASTER_ADMIN",
}

export enum CommonGroupCode {
  QA_TYPE_CODE = "qa_type_code",
  SETTING_TEXT_CODE = "setting_text_code",
  ARTICLE_TYPE_CODE = "article_type_code",
  PRODUCT_DELETE_REASON = "product_delete_reason",
}

export enum ProductDeleteCode {
  INCORRECT_REGIST = "INCORRECT_REGIST",
  NOT_USED = "NOT_USED",
}

export const TOP_OFFSET_Y = 1600;
