import { UserInfo } from '../api/member/model'
import { SelectedPreference } from '../api/preference/model'
import { PreferenceTypeCode } from '../constants/Preference.enum'
import AuthStore, { SessionUser } from '../store/AuthStore'
import { handleLogin, updateUserInfo } from '../hooks/tracker/TrackFunction'
import PreRenderMainStore from '../store/main/PreRenderMainStore'
import { SESSION_USER } from '../constants/Storage'

export const setSessionUserInStorage = async (
  userInfo: UserInfo
): Promise<boolean> => {
  try {
    const preferences: SelectedPreference[] = [
      {
        type: PreferenceTypeCode.HOME_APPLIANCE,
        preferenceListCodes: userInfo.homeAppliance || [],
      },
      {
        type: PreferenceTypeCode.HOUSEMATE,
        preferenceListCodes: userInfo.houseMate || [],
      },
      {
        type: PreferenceTypeCode.ROOM_SCALE,
        preferenceListCodes: userInfo.roomScale || [],
      },
    ]

    const user: SessionUser = {
      ...userInfo,
      preference: preferences,
    } as SessionUser
    await localStorage.setItem(
      SESSION_USER,
      JSON.stringify({
        idToken: user.idToken,
        memberUuid: user.memberUuid,
        nickname: user.nickname,
        sessionId: user.sessionId,
      })
    )
    AuthStore.setSessionUser(user)

    // 2023.01.10 앱 메인화면에 노출되어야할 데이터 먼저 셋업
    if (!PreRenderMainStore.appInitChecker) {
      await PreRenderMainStore.initMain(userInfo.memberUuid)
    }

    if (userInfo.memberUuid) {
      handleLogin(userInfo.memberUuid)
      updateUserInfo(userInfo)
    }

    return true
  } catch (error) {
    return false
  }
}

export const mergeAndSetSessionUserInStorage = async (
  userInfo: UserInfo
): Promise<boolean> => {
  const prevSessionUser = await getSessionUserFromStorage()
  return await setSessionUserInStorage({ ...prevSessionUser, ...userInfo })
}

export const getSessionUserFromStorage = async (): Promise<UserInfo | null> => {
  try {
    const session = await localStorage.getItem(SESSION_USER)

    if (session) {
      return JSON.parse(session) as UserInfo
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export const removeSessionUserFromStorage = () => {
  try {
    localStorage.removeItem(SESSION_USER)
    AuthStore.setSessionUser(null)
    return true
  } catch (error) {
    return false
  }
}
