import React, { CSSProperties } from "react";
import { HeaderItem } from "../../constants/Header";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../assets/icons/new_search.svg";
import MenuIcon from "../../assets/icons/new_menu.svg";
import Menu from "../../assets/icons/menu.svg";
import CloseIcon from "../../assets/icons/close.svg";
import HomeIconWhite from "../../assets/icons/gnb-home-w-32.svg";
import ShareIcon from "../../assets/icons/new_share.svg";
import HomeIcon from "../../assets/icons/new_home.svg";
import AngleIcon from "../../assets/icons/new_back_arrow.svg";
import ArrowWhite from "../../assets/icons/arrow_left_white.svg";
import { ContentsPopupMenu, OptionType } from "./ContentsPopupMenu";
import {
  //  onShare, onShareWithBranch,
  ShareInfo,
  onShare,
} from "../../utils/share";
import MainStore from "../../store/MainStore";
import TabStore from "../../store/TabStore";
import { useTracker } from "../../hooks/tracker";
import { getStringRemoteConfig } from "../../utils/remoteConfig";

import Bookmark from "../../assets/icons/icon-24-x-24-bookmark.svg";
import Coupon from "../../assets/icons/icon-coupon.svg";
import CartIcon from "../../assets/icons/icon_header_cart.svg";
import WriteIcon from "../../assets/icons/icon_header_pencil.svg";
import TouchableWithAuthCheck from "./TouchableWithAuthCheck";
import CommonStore from "../../store/CommonStore";
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
} from "../../constants/ActHistory";
import classes from "./TitleHeader.module.scss";
import clsx from "clsx";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";

const TitleHeader = ({
  wrapperRef,
  title, // 타이틀명
  commentCount, // 댓글갯수
  isBack, // 백버튼 사용 여부
  isHome, // 홈버튼 사용 여부
  isHomeWhite,
  rightItem, // 오른쪽에 들어갈 아이템 종류 (HeaderCode>HeaderItem 값)
  moreOptionMenuList, //rightItem이 MORE인 경우 팝업메뉴에 보여줄 옵션 목록
  hasSearch, // 검색 버튼 사용 여부
  contentsId, // api 처리시 필요한 해당 컨텐츠의 id
  navigation, // NavigationFunction 없는 별도 네비게이션 처리 있을시 필요
  style,
  showBorder, // 하단의 그림자 있는 보더 표시 여부
  buttonText, // 오른쪽 저장 버튼 텍스트
  backColor, // 노치 영역 포함 백그라운드 색깔
  needAuth, // auth체크 필요시 true
  shareInfo, // 공유 정보
  onClickBack, // back 버튼 클릭 이벤트
  onClickHome, // home 이동버튼 클릭 이벤트
  onClickCart, // 장바구니 클릭 이벤트
  onClickClose, // 닫기 버튼 클릭 이벤트
  onClickSave, // 저장 버튼 클릭 이벤트
  onClickMore, // ... more 버튼 클릭 이벤트
  onClickSaveInactivated, // 저장 버튼 비활성시 클릭 이벤트
  onClickWrite, // 글쓰기 버튼 클릭 이벤트
  onClickSearch,
  onPress, //전체 영역 Press 이벤트
  customRightItem,
  elementHeight,
}: {
  wrapperRef?: React.Ref<HTMLDivElement>;
  title?: string;
  commentCount?: string;
  isBack?: boolean;
  isHome?: boolean;
  isHomeWhite?: boolean;
  rightItem?: string; // HeaderItem enum 값
  moreOptionMenuList?: OptionType[];
  hasSearch?: boolean;
  contentsId?: string;
  navigation?: any;
  style?: CSSProperties;
  showBorder?: boolean;
  buttonText?: string;
  backColor?: string;
  needAuth?: boolean;
  shareInfo?: ShareInfo;
  onClickHome?: () => void;
  onClickCart?: () => void;
  onClickBack?: () => void;
  onClickClose?: () => void;
  onClickSave?: () => void;
  onClickMore?: (type: OptionType) => void;
  onClickSaveInactivated?: () => void;
  onClickWrite?: () => void;
  onClickSearch?: () => void;
  onPress?: () => void;
  customRightItem?: React.JSX.Element;
  elementHeight?: (height: number) => void;
}) => {
  const { setTabBarDisplay } = TabStore;
  const { showBottomSheet } = MainStore;
  const { t } = useTranslation();
  const { track } = useTracker();

  const elementHeaderHeight = (height: number) => {
    if (elementHeight) {
      elementHeight(height);
    }
  };

  const save = () => {
    if (onClickSave !== undefined) onClickSave();
  };

  const saveWhenInantivated = () => {
    if (onClickSaveInactivated !== undefined) onClickSaveInactivated();
  };

  const moveToSearch = () => {
    // navigate("SearchScreen");
  };

  const showDrawerMenu = () => {
    navigation.openDrawer();
  };

  const close = () => {
    if (onClickClose !== undefined) onClickClose();
    else goBack();
  };

  const back = () => {
    if (onClickBack !== undefined) onClickBack();
    else goBack();
  };

  const moveToHome = () => {
    if (onClickHome !== undefined) onClickHome();
    else {
      goTo("/main");
    }
  };

  const more = (type: OptionType) => {
    if (onClickMore !== undefined) onClickMore(type);
  };

  const renderRightItem = () => {
    if (rightItem !== null && rightItem !== undefined) {
      switch (rightItem) {
        case HeaderItem.NONE:
          return <></>;
        case HeaderItem.CLOSE:
          return <img src={CloseIcon} alt="Close" onClick={close} />;
        case HeaderItem.MENU:
          return <img src={MenuIcon} alt="Menu" onClick={showDrawerMenu} />;
        case HeaderItem.WHITE_MENU:
          return <img src={Menu} alt="Menu" onClick={showDrawerMenu} />;
        case HeaderItem.MORE:
          return (
            <ContentsPopupMenu
              optionTypesToShow={moreOptionMenuList || []}
              onMenuClick={more}
              backColor={backColor !== undefined ? "DARK" : "LIGHT"}
              needAuth={needAuth}
            />
          );
        case HeaderItem.SAVE_OFF:
          return (
            <div className={classes.button_wrap} style={{ width: 90 }}>
              <button
                className={classes.button_wrap}
                style={{
                  border: "1px solid #666",
                  padding: "8px 12px",
                  borderRadius: 100,
                }}
                aria-label="Save"
                onClick={saveWhenInantivated}
              >
                <span className={clsx(classes.button_text, classes.skip_text)}>
                  {buttonText ? buttonText : t("common.header.save")}
                </span>
              </button>
            </div>
          );
        case HeaderItem.SAVE_ON:
          return (
            <div className={classes.button_wrap}>
              <button
                className={classes.button}
                onClick={save}
                aria-label="Save"
              >
                <span className={classes.button_text}>
                  {buttonText ? buttonText : t("common.header.save")}
                </span>
              </button>
            </div>
          );
        case HeaderItem.APPLY_OFF:
          return (
            <div className={classes.button_wrap}>
              <button
                className={classes.skip_btn_off}
                onClick={saveWhenInantivated}
                aria-label="Save"
              >
                <span className={classes.skip_text_off}>
                  {buttonText ? buttonText : t("common.header.save")}
                </span>
              </button>
            </div>
          );
        case HeaderItem.APPLY_ON:
          return (
            <div className={classes.button_wrap}>
              <button
                className={classes.skip_btn}
                onClick={save}
                aria-label="Save"
              >
                <span className={classes.skip_text}>
                  {buttonText ? buttonText : t("common.header.save")}
                </span>
              </button>
            </div>
          );
        case HeaderItem.SHARE:
          return (
            <div className={classes.button_wrap}>
              <button
                aria-label="Share"
                onClick={() => {
                  const share_method = getStringRemoteConfig("share_method");
                  // if (share_method === "branch") {
                  //   void onShareWithBranch(shareInfo);
                  // } else {
                  void onShare(shareInfo);
                  // }

                  if (shareInfo) {
                    let screen = shareInfo.screen
                      ? shareInfo.screen?.toLowerCase()
                      : "";
                    if (screen.includes("mission")) {
                      screen = "MISSION";
                    }
                    track("click_share_button", {
                      content_id: shareInfo.targetId,
                      content_title: shareInfo.title,
                      content_type: screen,
                    });
                  }
                }}
              >
                <img src={ShareIcon} alt="Share" />
              </button>
            </div>
          );
        case HeaderItem.MY_COUPON_BOOKMARK:
          return (
            <div className={classes.buttons_hor_wrap}>
              <button
                style={{
                  width: 32,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 8,
                  marginRight: 4,
                }}
                onClick={() => {
                  CommonStore.callActHistory(
                    ActHistoryTypeCode.PAGE_CLICK,
                    ActHistoryTargetId.COUPON,
                    ActPageCode.MY_HOME
                  );
                  CommonStore.setShowDownloadAppDrive(true);
                  // navigate("CouponListScreen");
                }}
                aria-label="coupon"
              >
                <img src={Coupon} alt="Coupon" />
              </button>

              <button
                style={{
                  width: 32,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 4,
                  marginRight: 0,
                }}
                onClick={() => {
                  track("click_myhome_bookmark", {});
                  goTo("/Bookmark");
                }}
                aria-label="bookmark"
              >
                <img src={Bookmark} alt="Bookmark" />
              </button>
            </div>
          );
        case HeaderItem.WRITE:
          return (
            <div className={classes.button_wrap}>
              <TouchableWithAuthCheck
                onClick={() => onClickWrite && onClickWrite()}
              >
                <img src={WriteIcon} alt="WriteIcon" />
              </TouchableWithAuthCheck>
            </div>
          );
        case HeaderItem.CART:
          return (
            <div className={classes.button_wrap} style={{ marginLeft: 5 }}>
              <TouchableWithAuthCheck
                onClick={() => onClickCart && onClickCart()}
                aria-label="Cart"
              >
                <img src={CartIcon} alt="CartIcon" />
              </TouchableWithAuthCheck>
            </div>
          );
        case HeaderItem.REGISTER_OFF:
          return (
            <div className={classes.button_wrap}>
              <button
                className={classes.register_btn_off}
                onClick={saveWhenInantivated}
                aria-label="Save"
              >
                <span className={classes.register_text_off}>
                  {buttonText ? buttonText : t("common.header.save")}
                </span>
              </button>
            </div>
          );
        case HeaderItem.REGISTER_ON:
          return (
            <div className={classes.button_wrap}>
              <button
                className={classes.register_btn}
                onClick={save}
                aria-label="Save"
              >
                <span className={classes.register_text}>
                  {buttonText ? buttonText : t("common.header.save")}
                </span>
              </button>
            </div>
          );
        case HeaderItem.CUSTOM:
          if (!customRightItem) return <></>;
          else return customRightItem;
        default:
          return <></>;
      }
    }
  };

  return (
    <div
      ref={wrapperRef}
      onClick={onPress}
      className={clsx(
        classes.header,
        "common-header-system",
        showBorder && classes.title_border
      )}
      style={{
        backgroundColor: backColor !== undefined ? backColor : "#ffffff",
        ...(style || {}),
      }}
    >
      <div className={classes.inner_header}>
        <div className={classes.left}>
          {isBack !== false && (
            <img
              src={backColor !== undefined ? ArrowWhite : AngleIcon}
              alt="back"
              onClick={back}
            />
          )}
          {isHome && (
            <img
              src={isHomeWhite ? HomeIconWhite : HomeIcon}
              alt="home"
              onClick={moveToHome}
            />
          )}
        </div>

        <div className={classes.center}>
          {commentCount ? (
            <>
              <div className={classes.title_txt} aria-label="CommentScreen">
                {t("common.header.comment")}{" "}
              </div>
              <div className={classes.count} aria-label="Comment count">
                {commentCount}
              </div>
            </>
          ) : (
            <>
              <div
                className={classes.title_txt}
                style={{
                  color: backColor !== undefined ? "#fff" : "#222",
                }}
                aria-label="Title"
              >
                {title && title.length > 20
                  ? title.substring(0, 20) + "..."
                  : title}
              </div>
            </>
          )}
        </div>

        <div className={classes.right}>
          {hasSearch && (
            <img
              src={SearchIcon}
              alt="Search"
              onClick={() => {
                onClickSearch ? onClickSearch() : moveToSearch();
              }}
            />
          )}
          {renderRightItem()}
        </div>
      </div>
    </div>
  );
};

export default TitleHeader;
