import { action, computed, makeObservable, observable } from "mobx";
import {
  createMemberPreference,
  updateMemberPreference,
  validateNickname,
} from "../api/member/api";
import {
  NicknameValidationResult,
  CreateMemberPreferenceRequest,
  UpdateMemberPreferenceRequest,
} from "../api/member/model";
import { getPreferences } from "../api/preference/api";
import {
  Preference,
  PersonalInfo,
  SelectedPreference,
  PersonalInfoValidity,
} from "../api/preference/model";
import {
  createTempMemberAndSetPreferences,
  getTempMember,
} from "../api/tempMember/api";
import { SuccessOrNot } from "../constants/Common.enum";
import { PreferenceTypeCode } from "../constants/Preference.enum";
import { mergeAndSetTempMemberInStorage } from "../service/Preference.service";
import { mergeAndSetSessionUserInStorage } from "../service/Session.service";
import { validateNicknameFormat } from "../utils/nickname";
import AuthStore from "./AuthStore";
import RecommenderStore from "./RecommenderStore";
import MemberStore from "./MemberStore";
import MainStore from "./MainStore";
import { isBelow14 } from "../utils/age";
import { native } from "../native/native";
import { getStringRemoteConfig } from "../utils/remoteConfig";

const INITIAL_VALUE = {
  _preferenceSteps: [],
  _preferences: new Map<string, Preference | null>(),
  _personalInfo: {
    nickname: "",
    gender: "NA",
    birthYear: "NA",
  },
  _personalInfoValidity: {
    nicknameValidationResult: {},
    isNicknameValid: false,
  },
  _selectedPreferenceListCodes: new Map<string, Set<string>>(),
  _recommendedNicknameYn: "NA",
};

class PreferenceStore {
  @observable _step = 0;
  @observable _showFooter = true;

  @observable _preferenceSteps: string[] = INITIAL_VALUE._preferenceSteps;
  @observable _preferences: Map<string, Preference | null> =
    INITIAL_VALUE._preferences;
  @observable _personalInfo: PersonalInfo = INITIAL_VALUE._personalInfo;
  @observable _personalInfoValidity: PersonalInfoValidity =
    INITIAL_VALUE._personalInfoValidity;
  @observable _selectedPreferenceListCodes: Map<string, Set<string>> =
    INITIAL_VALUE._selectedPreferenceListCodes;
  @observable _recommendedNicknameYn: string =
    INITIAL_VALUE._recommendedNicknameYn;

  constructor() {
    makeObservable(this);
    this.initPreferenceSteps();
  }

  @computed get step() {
    return this._step;
  }

  @computed get showFooter() {
    return this._showFooter;
  }

  @computed get myNickname() {
    return this._personalInfo.nickname;
  }

  @computed get myRecommendedNicknameYn() {
    return this._recommendedNicknameYn;
  }

  @computed get nicknameValidationResult() {
    return this._personalInfoValidity.nicknameValidationResult;
  }

  @computed get isNicknameValid() {
    return this._personalInfoValidity.isNicknameValid;
  }

  @computed get myGender() {
    return this._personalInfo.gender;
  }

  @computed get myBirthYear() {
    return this._personalInfo.birthYear;
  }

  @computed get homeAppliancePreference() {
    return this._preferences.get(PreferenceTypeCode.HOME_APPLIANCE);
  }
  @computed get housematePreference() {
    return this._preferences.get(PreferenceTypeCode.HOUSEMATE);
  }
  @computed get roomScalePreference() {
    return this._preferences.get(PreferenceTypeCode.ROOM_SCALE);
  }
  @computed get preferenceSteps() {
    return this._preferenceSteps;
  }
  @computed get selectedHomeAppliancePreferenceListCodes() {
    return (
      this._selectedPreferenceListCodes.get(
        PreferenceTypeCode.HOME_APPLIANCE
      ) || new Set<string>()
    );
  }

  @computed get selectedHousematePreferenceListCodes() {
    return (
      this._selectedPreferenceListCodes.get(PreferenceTypeCode.HOUSEMATE) ||
      new Set<string>()
    );
  }
  @computed get selectedRoomScalePreferenceListCodes() {
    return (
      this._selectedPreferenceListCodes.get(PreferenceTypeCode.ROOM_SCALE) ||
      new Set<string>()
    );
  }

  @computed get isValidBirthyear() {
    return (
      this._personalInfo.birthYear !== "NA" &&
      this._personalInfo.birthYear?.length === 4 &&
      Number(this._personalInfo.birthYear) >= 1930 &&
      !isBelow14(this._personalInfo.birthYear)
    );
  }

  @computed get isMyInfoSelectionSatisfied() {
    if (getStringRemoteConfig("show_gender_birthyear") === "true") {
      // 성별,출생년도 필수 입력
      if (
        (RecommenderStore.recommenderYn === "Y" ||
          getStringRemoteConfig("show_recommend_input") === "true") &&
        AuthStore.sessionUser
      ) {
        return (
          this._personalInfoValidity.isNicknameValid &&
          RecommenderStore.isRecommenderNicknameComplete &&
          this._personalInfo.gender !== "NA" &&
          this.isValidBirthyear
        );
      } else {
        return (
          this._personalInfoValidity.isNicknameValid &&
          this._personalInfo.gender !== "NA" &&
          this.isValidBirthyear
        );
      }
    } else {
      if (
        (RecommenderStore.recommenderYn === "Y" ||
          getStringRemoteConfig("show_recommend_input") === "true") &&
        AuthStore.sessionUser
      ) {
        return (
          this._personalInfoValidity.isNicknameValid &&
          RecommenderStore.isRecommenderNicknameComplete
        );
      } else {
        return this._personalInfoValidity.isNicknameValid;
      }
    }
  }

  @computed get isHousemateSelectionSatisfied() {
    return !!(
      this.housematePreference &&
      this.selectedHousematePreferenceListCodes &&
      this.housematePreference.min <=
        this.selectedHousematePreferenceListCodes.size &&
      this.housematePreference.max >=
        this.selectedHousematePreferenceListCodes.size
    );
  }

  @computed get isRoomScaleSelectionSatisfied() {
    return !!(
      this.roomScalePreference &&
      this.selectedRoomScalePreferenceListCodes &&
      this.roomScalePreference.min <=
        this.selectedRoomScalePreferenceListCodes.size &&
      this.roomScalePreference.max >=
        this.selectedRoomScalePreferenceListCodes.size
    );
  }

  @computed get isHomeApplianceSelectionSatisfied() {
    return !!(
      this.homeAppliancePreference &&
      this.selectedHomeAppliancePreferenceListCodes &&
      this.homeAppliancePreference.min <=
        this.selectedHomeAppliancePreferenceListCodes.size &&
      this.homeAppliancePreference.max >=
        this.selectedHomeAppliancePreferenceListCodes.size
    );
  }
  @action setStep = (setter: (prevStep: number) => number) => {
    this._step = setter(this._step);
  };

  @action setShowFooter = (showFooter: boolean) => {
    this._showFooter = showFooter;
  };

  @action initPreferenceSteps = () => {
    this._selectedPreferenceListCodes.set(
      PreferenceTypeCode.HOME_APPLIANCE,
      new Set<string>()
    );
    this._selectedPreferenceListCodes.set(
      PreferenceTypeCode.HOUSEMATE,
      new Set<string>()
    );
    this._selectedPreferenceListCodes.set(
      PreferenceTypeCode.ROOM_SCALE,
      new Set<string>()
    );
  };

  @action clearPreferenceSteps = () => {
    this._preferenceSteps = INITIAL_VALUE._preferenceSteps;
  };

  @action clearPreferenceSelection = () => {
    this._preferences = INITIAL_VALUE._preferences;
    this._personalInfo = INITIAL_VALUE._personalInfo;
    this._personalInfoValidity = INITIAL_VALUE._personalInfoValidity;
    this._selectedPreferenceListCodes =
      INITIAL_VALUE._selectedPreferenceListCodes;
  };

  @action addPreferenceSteps = (step: string) => {
    this._preferenceSteps.push(step);
  };

  @action clearAndRetrievePreferenceSteps = async () => {
    const res = await getPreferences();
    this.clearPreferenceSteps();
    AuthStore.canEnterMainNav ||
      this.addPreferenceSteps(PreferenceTypeCode.MY_INFO);
    if (res) {
      res.forEach((item) => {
        this.addPreferenceSteps(item.type);
        this._preferences.set(item.type, item);
      });
    }

    // 회원가입 플로우에만 노출(비회원은 미노출)
    if (AuthStore.sessionUser) {
      await MemberStore.getRankingPosters();
      const response = MemberStore.userStyleRankingPosters;
      if (response.length > 0) {
        const defaultFollower = response
          .filter((r) => r.isDefaultFollower === "Y")
          .map((r) => r.memberId);
        MemberStore.addFollowMDefault(defaultFollower);
        const res = this.preferenceSteps.findIndex(
          (x) => x === PreferenceTypeCode.USER_STYLE
        );
        if (res === -1) this.addPreferenceSteps(PreferenceTypeCode.USER_STYLE);
      }
    }
  };

  @action clearAndRetrievePreferenceStepsForModify = async () => {
    const res = await getPreferences();
    this.clearPreferenceSteps();
    AuthStore.canEnterMainNav ||
      this.addPreferenceSteps(PreferenceTypeCode.MY_INFO);
    if (res) {
      res.forEach((item) => {
        this.addPreferenceSteps(item.type);
        this._preferences.set(item.type, item);
      });
    }
  };

  @action setIsNicknameValid = (isNicknameValid: boolean) => {
    this._personalInfoValidity.isNicknameValid = isNicknameValid;
  };

  @action setNicknameValidationResult = (
    nicknameValidationResult: NicknameValidationResult
  ) => {
    this._personalInfoValidity.nicknameValidationResult =
      nicknameValidationResult;
  };

  @action setMyNickname = async (nickname: string) => {
    this._personalInfo.nickname = nickname;
    let isNicknameValid = false;

    if (validateNicknameFormat(nickname)) {
      const res = await validateNickname(nickname);
      this.setNicknameValidationResult(res);

      if (res && res.isExist === "N" && !res.hasBadWord) {
        isNicknameValid = true;
      }
    }
    this.setIsNicknameValid(isNicknameValid);
  };

  @action setMyGender = (gender: string) => {
    this._personalInfo.gender = gender;
  };

  @action setMyBirthYear = (birthYear: string) => {
    this._personalInfo.birthYear = birthYear;
  };

  @action setMyRecommendedNicknameYn = (Yn: string) => {
    this._recommendedNicknameYn = Yn;
  };

  @action selectHomeAppliancePreferenceListCodes = (code: string) => {
    if (this.selectedHomeAppliancePreferenceListCodes?.has(code)) {
      this.selectedHomeAppliancePreferenceListCodes?.delete(code);
    } else {
      this.selectedHomeAppliancePreferenceListCodes?.add(code);
    }
  };

  @action selectHousematePreferenceListCodes = (code: string) => {
    if (this.selectedHousematePreferenceListCodes?.has(code)) {
      this.selectedHousematePreferenceListCodes?.delete(code);
    } else {
      this.selectedHousematePreferenceListCodes?.add(code);
    }
  };

  @action clearPreselections = () => {
    this.selectedHousematePreferenceListCodes?.clear();
    this.selectedRoomScalePreferenceListCodes?.clear();
    this.selectedHomeAppliancePreferenceListCodes?.clear();
  };

  @action selectRoomScalePreferenceListCodes = (code: string) => {
    if (this.selectedRoomScalePreferenceListCodes?.has(code)) {
      this.selectedRoomScalePreferenceListCodes?.delete(code);
    } else {
      this.selectedRoomScalePreferenceListCodes?.add(code);
    }
  };

  @action savePreferenceSelectionForTempMember = async () => {
    const selectedPreferences: SelectedPreference[] = [];

    this._selectedPreferenceListCodes.forEach((value, key) => {
      selectedPreferences.push({
        type: key as PreferenceTypeCode,
        preferenceListCodes: Array.from(value || []),
      });
    });
    const token = await native.getPushToken();

    const tempMemberUUID = await createTempMemberAndSetPreferences({
      nickname: this.myNickname,
      genderCode: this.myGender,
      birthYear: this.myBirthYear || "0",
      houseMate: Array.from(this.selectedHousematePreferenceListCodes),
      roomScale: Array.from(this.selectedRoomScalePreferenceListCodes),
      homeAppliance: Array.from(this.selectedHomeAppliancePreferenceListCodes),
      devicePushToken: token,
      recommendedNicknameYn: this.myRecommendedNicknameYn || "NA",
    });
    if (tempMemberUUID) {
      const tempMember = await getTempMember(tempMemberUUID);

      await mergeAndSetTempMemberInStorage({
        nickname: this.myNickname,
        preferences: selectedPreferences,
        tempMemberUUID: tempMemberUUID,
        tempMemberId: tempMember?.tempMemberId,
        recommendedNicknameYn: this.myRecommendedNicknameYn,
      });

      // await AuthStore.getSessionUserFromStorageToStore();
      await AuthStore.getTempMemberFromStorageToStore();
    }
  };

  @action createPreferenceSelectionForMember = async () => {
    const request: CreateMemberPreferenceRequest = {
      nickname: this.myNickname,
      genderCode: this.myGender,
      birthYear: this.myBirthYear || "NA",
      houseMate: Array.from(this.selectedHousematePreferenceListCodes),
      roomScale: Array.from(this.selectedRoomScalePreferenceListCodes),
      homeAppliance: Array.from(this.selectedHomeAppliancePreferenceListCodes),
      recommendedNicknameYn: this.myRecommendedNicknameYn,
    };

    const response = await createMemberPreference(request);

    if (response.successOrNot === SuccessOrNot.Y) {
      await mergeAndSetSessionUserInStorage({
        nickname: this.myNickname,
        genderCode: this.myGender,
        birthYear: this.myBirthYear || "NA",
        houseMate: Array.from(this.selectedHousematePreferenceListCodes),
        roomScale: Array.from(this.selectedRoomScalePreferenceListCodes),
        homeAppliance: Array.from(
          this.selectedHomeAppliancePreferenceListCodes
        ),
        recommendedNicknameYn: this.myRecommendedNicknameYn,
      });

      await AuthStore.getSessionUserFromStorageToStore();
      if (response.data) {
        MainStore.setShowMemberJoinVoltSavePop(true);
      }
    }
  };

  @action updatePreferenceSelectionForMember = async () => {
    const request: UpdateMemberPreferenceRequest = {
      houseMate: Array.from(this.selectedHousematePreferenceListCodes),
      roomScale: Array.from(this.selectedRoomScalePreferenceListCodes),
      homeAppliance: Array.from(this.selectedHomeAppliancePreferenceListCodes),
    };

    const response = await updateMemberPreference(request);

    if (response.successOrNot === SuccessOrNot.Y) {
      await mergeAndSetSessionUserInStorage({
        houseMate: Array.from(this.selectedHousematePreferenceListCodes),
        roomScale: Array.from(this.selectedRoomScalePreferenceListCodes),
        homeAppliance: Array.from(
          this.selectedHomeAppliancePreferenceListCodes
        ),
      });

      await AuthStore.getSessionUserFromStorageToStore();
    }
  };

  @action clear = () => {
    this._step = 0;

    this._preferenceSteps = INITIAL_VALUE._preferenceSteps;
    this._preferences = INITIAL_VALUE._preferences;
    this._personalInfo = INITIAL_VALUE._personalInfo;
    this._personalInfoValidity = INITIAL_VALUE._personalInfoValidity;
    this._selectedPreferenceListCodes =
      INITIAL_VALUE._selectedPreferenceListCodes;

    this.initPreferenceSteps();
  };
}

export default new PreferenceStore();
